import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NgIf, NgFor, CurrencyPipe, NgClass } from '@angular/common';

import { SearchService } from '../_services/search.service';
import { NotificationService } from '../_services/notification.service';
import { ItemsService } from '../_services/items.service';
import { FeatureService } from '../_services/feature.service';
import { combineLatest, Subscription } from 'rxjs';
import { isMobile } from '../_util/mobile.util';
import { BadgeComponent } from '../badge/badge.component';

import {
  PrimaryButtonComponent,
  SecondaryButtonComponent,
  IconComponent,
  TooltipDirective,
  MinimalIconButtonComponent
} from '@limblecmms/lim-ui';

import { ShippingDetailsComponent } from '../shipping-details/shipping-details.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faDollarCircle } from '@fortawesome/pro-regular-svg-icons';
import { faSparkles } from '@fortawesome/pro-solid-svg-icons';
import { EventBusService } from '../_shared/event-bus.service';
import { ItemGroupMatch } from '../_types/attributeGrouping';

@Component({
  selector: 'part-search-item-card',
  templateUrl: './part-search-item-card.component.html',
  styleUrls: ['./part-search-item-card.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    PrimaryButtonComponent,
    NgFor,
    CurrencyPipe,
    SecondaryButtonComponent,
    IconComponent,
    TooltipDirective,
    MinimalIconButtonComponent,
    NgClass,
    ShippingDetailsComponent,
    FontAwesomeModule,
    BadgeComponent
  ]
})
export class PartSearchItemCardComponent {
  @Input() item = {
    id: 0,
    title: '',
    description: null,
    price: 0,
    pricePer: 0,
    per: 1,
    mainImageUrl: '',
    productUrl: null,
    manufacturerPartNumber: null,
    savings: null,
    vendor: {
      id: null,
      logoUrl: null,
      name: null,
      key: null
    },
    matches: [] as ItemGroupMatch[],
    items: [],
    hideVendorLogo: false,
    user_hidden: false,
    shipping_days: null,
    inStock: false,
    labels: {},
    source: null
  };

  @Input() view = 'all-items';

  public qty: any = 1;
  public itemTitleClamped: boolean = false;
  public filteredSearchResults: object | any = {};
  public hiddenItems: any = [];
  public sortBy: string | null = '';
  public searchQuery: string | null = '';
  public isLoading: boolean = true;
  public dollarCircle = faDollarCircle;
  public showLabelControls: boolean = false;
  public vendorTooltip: string = '';
  public isMobile: boolean = false;
  public isItemsRestricted: boolean = false;
  public vendorLogoKeys: string[] = [];
  public match: ItemGroupMatch | undefined;
  public faSparkles = faSparkles;
  public showSmartGroupings: boolean = false;

  public filteredSearchResultsSub?: Subscription;
  public searchResultsSub?: Subscription;
  public hiddenItemsSub?: Subscription;
  public sortBySub?: Subscription;
  public querySub?: Subscription;
  public searchCompleteSub?: Subscription;
  public isPreviewingSub?: Subscription;
  public featuresSub?: Subscription;
  public isItemsRestrictedSub?: Subscription;
  public selectedAttributeGroup?: Subscription;

  constructor(
    public searchService: SearchService,
    public notificationService: NotificationService,
    public itemsService: ItemsService,
    public featureService: FeatureService,
    private readonly eventBus: EventBusService,
    private readonly router: Router
  ) {}

  ngOnInit(): void {
    this.isMobile = isMobile();

    //determining if the title was clamped or not based on the length of the title.
    //This length comparison is arbitrary based on how many characters would fit on 3 lines.
    this.itemTitleClamped = this.item.title.length > 105 ? true : false;

    this.filteredSearchResultsSub = this.searchService.filteredSearchResults$.subscribe((results) => {
      this.filteredSearchResults = results;
    });

    this.searchResultsSub = this.searchService.searchResults$.subscribe((results) => {
      this.buildToolTip();
    });

    this.hiddenItemsSub = this.searchService.hiddenItems$.subscribe((hiddenItems) => {
      this.hiddenItems = hiddenItems;
    });

    this.sortBySub = this.searchService.sortBy$.subscribe((sortBy) => {
      this.sortBy = sortBy;
    });

    this.querySub = this.searchService.query$.subscribe((query) => {
      this.searchQuery = query;
    });

    this.searchCompleteSub = this.searchService.searchComplete$.subscribe((isComplete) => {
      this.isLoading = !isComplete;
    });

    this.isPreviewingSub = this.searchService.isPreviewing$.subscribe((isPreviewing) => {
      this.isLoading = !isPreviewing;
    });

    this.featuresSub = this.featureService.features$.subscribe((features) => {
      this.showLabelControls = this.featureService.enabled('super-admin');
      this.showSmartGroupings = this.featureService.enabled('smart-groupings');
    });

    this.isItemsRestrictedSub = this.searchService._isItemsRestricted.subscribe((isItemsRestricted) => {
      this.isItemsRestricted = isItemsRestricted;
    });

    this.selectedAttributeGroup = combineLatest([
      this.searchService.selectedAttributeGroups$,
      this.searchService.filters$
    ]).subscribe(([attributeGroup, filters]) => {
      if (this.view === 'similar-items') {
        this.isLoading = false;
      }
      if (attributeGroup) {
        const group = Object.values(attributeGroup)[0];
        if (this.item.matches) {
          const match = this.item.matches.find(({ itemGroupId }) => itemGroupId === group?.id);

          if (match) {
            this.match = { ...match, vendors: [], count: 0 };
          } else {
            this.match = { vendors: [] as any[], count: 0 } as any;
          }
        }

        if (this.match && this.match.items && Object.keys(filters).length) {
          const filteredGroupItems = this.searchService.filterGroupItems(this.match.items);
          const vendors = this.searchService.getVendorsFromFilteredGroupItems(filteredGroupItems);

          this.match.vendors = vendors;
          this.match.count = filteredGroupItems.length;

          this.vendorLogoKeys = this.match.vendors.filter(({ id }) => id !== this.item.vendor.id).map(({ key }) => key);
        }
      }
    });
  }

  ngOnDestory(): void {
    this.filteredSearchResultsSub?.unsubscribe();
    this.searchResultsSub?.unsubscribe();
    this.hiddenItemsSub?.unsubscribe();
    this.sortBySub?.unsubscribe();
    this.querySub?.unsubscribe();
    this.searchCompleteSub?.unsubscribe();
    this.isPreviewingSub?.unsubscribe();
    this.featuresSub?.unsubscribe();
    this.isItemsRestrictedSub?.unsubscribe();
    this.selectedAttributeGroup?.unsubscribe();
  }

  buildToolTip(): void {
    const vendors = (this.match && this.match.vendors) || [];

    if (!vendors || vendors.length == 0) {
      this.vendorTooltip = '';
      return;
    }

    let tooltip = 'Compare this item on ';

    vendors.forEach((vendor: any, index: number) => {
      tooltip += vendor.name;
      if (index < vendors.length - 2) {
        tooltip += ', ';
      } else if (index === vendors.length - 2) {
        tooltip += ' and ';
      }
    });

    this.vendorTooltip = tooltip;
  }

  openItemSite(productUrl: any, vendor: any, itemId: number) {
    if (this.isLoading) return;
    this.searchService.openItemSite(productUrl, vendor, itemId);
  }

  async hideItem(item: any) {
    if (this.isLoading) return;

    const removedItemIndex = this.filteredSearchResults.findIndex((i: any) => i.id === item.id);
    this.hiddenItems.unshift({ ...item, removedItemIndex });
    //update the removedItemIndex for all items already hidden if needed
    for (let i = 1; i < this.hiddenItems.length; i++) {
      if (this.hiddenItems[i].removedItemIndex > removedItemIndex) {
        this.hiddenItems[i].removedItemIndex--;
      }
    }
    this.searchService.updateHiddenItems(this.hiddenItems);
    this.filteredSearchResults = this.filteredSearchResults.filter((i: any) => i.id !== item.id);
    this.searchService.updateFilteredResults(this.filteredSearchResults);
    await this.searchService.updateMeta();
    this.itemsService.updateItem(item.id, true).subscribe();
    this.notificationService.hideItem({ ...item, removedItemIndex });
  }

  showDetails(item: any) {
    if (this.isItemsRestricted) {
      if (this.isMobile) {
        this.router.navigate(['/register', { return: this.router.url }]);
      } else {
        this.eventBus.emit('SignupFlyout.Show', {});
      }
      return;
    }

    this.itemsService.trackClick(item.id, 'details').subscribe();

    if (this.isLoading) return;

    const queryParams = { group: this.match?.itemGroupValueId };
    this.searchService.setSelectedItem(item);
    this.searchService.setDetailsPageSelectedItem(item);
    this.searchService.startProgressBar();
    this.router.navigate(['/details', `${item.id}`], { queryParams });
  }

  setLabel(key: string, value: any) {
    if (this.item.id) {
      if (this.searchService.getItemLabel(this.item.id, key) == value) {
        value = null;
      }
      this.itemsService.updateLabel(this.item.id, key, value).subscribe();
      this.searchService.setItemLabel(this.item.id, key, value);
    }

    return false;
  }
}
