import { Component } from '@angular/core';
import { NgFor, NgIf } from '@angular/common';
import { SearchService } from '../_services/search.service';
import { Subscription } from 'rxjs';
import { TooltipDirective } from '@limblecmms/lim-ui';

@Component({
  selector: 'pre-search-hero',
  standalone: true,
  imports: [NgIf, NgFor, TooltipDirective],
  templateUrl: './pre-search-hero.component.html',
  styleUrl: './pre-search-hero.component.scss'
})
export class PreSearchHeroComponent {
  public preSearchDetails: any = null;
  public mainProductDetails: any;
  public allProductFeatures: any;
  public featureList: any = [];
  public maxFeaturesToShow = 8;
  public searchResultsMetaSub?: Subscription;

  constructor(public searchService: SearchService) {}

  ngOnInit() {
    this.searchResultsMetaSub = this.searchService.searchResultsMeta$.subscribe((searchResultsMeta) => {
      if (searchResultsMeta.preSearchDetails) {
        this.preSearchDetails = searchResultsMeta.preSearchDetails;
        this.mainProductDetails = this.preSearchDetails.mainProductDetails;
        this.allProductFeatures = this.preSearchDetails.allProductFeatures;
        this.featureList = [];
        this.processFeatures();
      }
    });
  }

  processFeatures() {
    this.featureList.push({ name: 'Typical Price', value: this.mainProductDetails.typicalPrice });
    if (Object.keys(this.allProductFeatures).length) {
      let noOfFeatures = 1;
      for (let feature in this.allProductFeatures) {
        this.featureList.push({ name: feature, value: this.allProductFeatures[feature] });
        noOfFeatures++;
        if (noOfFeatures === this.maxFeaturesToShow) {
          break;
        }
      }
    }
  }
}
