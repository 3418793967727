import { AfterContentInit, Component } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

// SEARCH-400, We use a custom feedback derived from @betahuhn/feedback-js. We cloned the node_module into this standalone component, and then compile
// the dist by cd into the folder, making the edits and running npm run build. CSS and JS is embedded, and then modifiable from this .ts - Nice and simple, for now :)
import Feedback from './feedback-js';

import { CONFIG } from '../../environments/environment';
const feedbackUrl = CONFIG.API_URL + 'search/feedback';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback-hover.component.html',
  styleUrls: ['./feedback-hover.component.css'],
  standalone: true
})
export class FeedbackComponent implements AfterContentInit {
  constructor(private http: HttpClient) {}

  ngAfterContentInit(): void {}

  ngOnDestroy(): void {
    this.removeEventListener();
  }

  private setupEventListener(): void {
    window.addEventListener('feedback-submit', this.feedbackSubmitHandler.bind(this));
  }

  private removeEventListener(): void {
    window.removeEventListener('feedback-submit', this.feedbackSubmitHandler);
  }

  feedbackSubmitHandler(event: any): Observable<any> {
    console.log(event.detail);
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      withCredentials: true
    };

    const request = this.http.post(
      feedbackUrl,
      {
        event: event.detail
      },
      httpOptions
    );

    request.subscribe({
      next: (data: any) => {
        console.log('Feedback submitted successfully');
      },
      error: (response) => {
        console.log('Error submitting feedback');
      }
    });

    return request;
  }

  ngOnInit(): void {
    // check if the feedback button already exists
    const feedbackButton = document.getElementById('feedback-root');

    if (feedbackButton) {
      return;
    }

    const options = {
      events: true,
      id: 'feedback-button',
      emailField: false,
      title: 'Limble Search',
      primary: 'rgb(40,158,73)',
      background: '#F2F2F2',
      fontWeight: '600', // SEARCH-400
      failedTitle: 'Oops, an error ocurred!',
      failedMessage: 'Please try again. If this keeps happening, please send an email to search@limblecmms.com instead.'
    };

    new Feedback(options).renderButton();

    this.setupEventListener();
  }
}
