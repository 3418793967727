import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationService } from 'src/app/_services/notification.service';
import { SearchService } from 'src/app/_services/search.service';

@Component({
  selector: 'app-item-hidden-notification',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './item-hidden-notification.component.html',
  styleUrls: ['./item-hidden-notification.component.scss']
})
export class ItemHiddenNotificationComponent {
  @Input() item!: any;

  @Input() notificationType: string = 'resultItem';

  constructor(public searchService: SearchService, public notificationService: NotificationService) {}

  close(): void {
    this.notificationService.closeHideItem();
  }
  cancel(): void {
    if (this.notificationType === 'groupedItem') {
      this.notificationService.changeNotificationType('groupedItem');
    }
    this.notificationService.closeHideItem();
  }
}
