import { NgClass, NgIf } from '@angular/common';
import type { OnDestroy, OnInit } from '@angular/core';
import { Component, EventEmitter, Output } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { Subscription } from 'rxjs';

import { AuthService } from '../_services/auth.service';

import { MinimalIconButtonComponent } from '@limblecmms/lim-ui';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';

@Component({
  selector: 'mobile-nav',
  standalone: true,
  imports: [MinimalIconButtonComponent, NgClass, NgIf, RouterLink, FontAwesomeModule],
  templateUrl: './mobile-nav.component.html',
  styleUrl: './mobile-nav.component.scss'
})
export class MobileNavComponent implements OnInit, OnDestroy {
  @Output() public readonly hideMobileMenu = new EventEmitter();

  public faxmark = faXmark;
  public isLoggedIn: Boolean = false;
  public authSub?: Subscription;

  constructor(private authService: AuthService, public router: Router) {}

  ngOnInit(): void {
    this.authSub = this.authService.isLoggedIn$.subscribe((isLoggedIn) => {
      this.isLoggedIn = isLoggedIn;
    });

    console.log('isLoggedin: ', this.isLoggedIn);
  }
  hideSideMenu() {
    this.hideMobileMenu.emit();
  }

  logout(): void {
    this.authService.logout();
  }

  ngOnDestroy(): void {
    this.authSub?.unsubscribe();
  }
}
