import { Component, Input, OnChanges, SimpleChanges, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgIf } from '@angular/common';
import { Subject, debounceTime, tap } from 'rxjs';

import { isMobile } from '../_util/mobile.util';

import { SearchService } from '../_services/search.service';
import { NotificationService } from '../_services/notification.service';
import { ItemsService } from '../_services/items.service';

import { IconComponent } from '@limblecmms/lim-ui';

@Component({
  selector: 'query-quality-dropdown',
  templateUrl: './query-quality-dropdown.component.html',
  styleUrls: ['./query-quality-dropdown.component.scss'],
  standalone: true,
  imports: [IconComponent, NgIf]
})
export class QueryQualityDropdownComponent implements OnChanges, OnInit {
  @Input() query: any = null;
  @Input() show: boolean = false;

  private _query$ = new Subject<any>();
  private querySubscription: any;
  private minLength = 3;

  public isMobile: boolean = false;

  public facets: any = {
    part_number: false,
    product_type: false,
    brand: false,
    specifications: false
  };

  public isLoading: boolean = false;

  public quality: any = 'low';

  constructor(
    public searchService: SearchService,
    public router: Router,
    public notificationService: NotificationService,
    public itemsService: ItemsService
  ) {
    this._query$
      .pipe(
        tap(() => {
          if (this.querySubscription) {
            this.querySubscription.unsubscribe();
          }
          this.reset();
          this.isLoading = true;
        }),
        debounceTime(400)
      )
      .subscribe((query: any) => {
        this.isLoading = true;

        if (this.querySubscription) {
          this.querySubscription.unsubscribe();
        }

        // This prevents the query quality from being called after a search is started
        if (this.searchService._percentComplete.getValue() < 100 && this.searchService._percentComplete.getValue() > 0) {
          return;
        }
        this.querySubscription = this.searchService.getQueryQuality(query).subscribe((response: any) => {
          this.isLoading = false;
          this.facets = response.facets;
          this.quality = response.quality;
        });
      });
  }

  ngOnInit(): void {
    this.isMobile = isMobile();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('query')) {
      let query = changes['query'].currentValue;

      if (query && query.length >= this.minLength) {
        this._query$.next(query);
      } else {
        this.isLoading = false;
        this.reset();
      }
      return;
    }
  }

  reset() {
    this.facets = {
      part_number: false,
      product_type: false,
      brand: false,
      specifications: false
    };

    this.quality = 'low';
  }
}
