import { Injectable, QueryList } from '@angular/core';
import { HttpClient, HttpHeaders, withInterceptorsFromDi } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { identifierName, isNgTemplate } from '@angular/compiler';
import { CONFIG } from '../../environments/environment';

export enum NotificationType {
  Success = 'success',
  Error = 'error',
  Warning = 'warning',
  Info = 'info'
}

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private readonly _notifications = new BehaviorSubject<any>(null);
  readonly notifications$ = this._notifications.asObservable();
  private readonly _hiddenItemNotification = new BehaviorSubject<any | null>(null);
  readonly hiddenItemNotification$ = this._hiddenItemNotification.asObservable();
  private readonly _hiddenItemNotificationType = new BehaviorSubject<any | null>(null);
  readonly hiddenItemNotificationType$ = this._hiddenItemNotificationType.asObservable();

  constructor() {}

  notify(type: NotificationType, message: string, useToastr: boolean = true): void {
    this._notifications.next({ type, message });
  }

  success(message: string): void {
    this.notify(NotificationType.Success, message);
  }

  error(message: string): void {
    this.notify(NotificationType.Error, message);
  }

  hideItem(item: any): void {
    this._hiddenItemNotification.next(item);
    setTimeout(() => {
      this._hiddenItemNotification.next(null);
    }, 5000);
  }

  closeHideItem(): void {
    this._hiddenItemNotification.next(null);
    this._hiddenItemNotificationType.next(null);
  }

  changeNotificationType(notificationType: string): void {
    this._hiddenItemNotificationType.next(notificationType);
  }
}
