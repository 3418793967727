/**
 * This file is intended for code that needs to run before angular bootstraps.
 */

import { APP_INITIALIZER } from '@angular/core';
import { HeapService } from './_services/heap.service';

export const appInitProvider = {
  provide: APP_INITIALIZER,
  useFactory: init,
  multi: true,
  deps: [HeapService]
};

function init(heapService: HeapService): () => Promise<void> {
  return async () => {
    await heapService.load();
  };
}
