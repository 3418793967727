import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from '../_services/auth.service';
import { NotificationService } from '../_services/notification.service';
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { NgIf } from '@angular/common';
import { Aliases, IconComponent } from '@limblecmms/lim-ui';
import { CONFIG } from '../../environments/environment';

@Component({
  selector: 'reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
  standalone: true,
  imports: [FormsModule, NgIf, IconComponent]
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  resetPasswordForm: any = {
    password: null,
    confirmPassword: null
  };

  private token: string = '';
  private loggedInSubscriber?: Subscription;
  private authErrorSubscriber?: Subscription;
  public resetPasswordError: boolean = false;
  public errorMsg: string = '';
  protected eyeSlashIcon: Aliases = 'eyeSlashRegular';
  protected eyeIcon: Aliases = 'eyeRegular';
  public showPassword: boolean = false;
  public showConfirmPassword: boolean = false;

  constructor(
    private authService: AuthService,
    private notificationService: NotificationService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    // Get token from router params
    this.route.queryParams.subscribe((params) => {
      this.token = params['token'] ?? '';
    });
  }

  ngOnDestroy(): void {
    this.authErrorSubscriber?.unsubscribe();
    this.loggedInSubscriber?.unsubscribe();
  }

  resetPassword(): void {
    const { password, confirmPassword } = this.resetPasswordForm;

    if (password !== confirmPassword) {
      this.resetPasswordError = true;
      this.errorMsg = `Passwords do not match`;
      return;
    }

    this.authService.resetPassword(this.token, password).subscribe({
      next: (data) => {
        this.resetPasswordError = false;
        this.notificationService.success('Your password has been reset successfully. You can now log in.');
        this.router.navigate(['/login']);
      },
      error: (err) => {
        this.resetPasswordError = true;
        this.errorMsg = err.error.message;
      }
    });
  }

  public togglePasswordVisibility(passwordType: string) {
    const password = document.querySelector(`#${passwordType}`);
    if (passwordType === 'newPassword') {
      this.showPassword = !this.showPassword;
    } else {
      this.showConfirmPassword = !this.showConfirmPassword;
    }
    //  toggle the type attribute
    const type = password?.getAttribute('type') === 'password' ? 'text' : 'password';
    password?.setAttribute('type', type);
  }
}
