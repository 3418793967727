<!--this modal is a combination of the part-search-field and query-quality-dropdown components. This modal should be used for responsive search state-->
<lim-ui-modal>
  <lim-ui-modal-body>
    <section>
      <div class="search-container">
        <fa-icon [icon]="chevronLeft" size="sm" (click)="close()"></fa-icon>
        <div class="part-search-container">
          <input
            name="part_search"
            id="modal-part-search-field"
            [(ngModel)]="query"
            class="part-search"
            (keydown.enter)="submit()"
            placeholder="Search by Part Name, Part Detail, etc."
          />
          <fa-icon
            [icon]="circleXmark"
            size="xs"
            style="color: #b3b3b3; padding: 8px"
            (click)="clearSearchQuery()"
          ></fa-icon>
          <button class="search-button" (click)="submit()">
            <lim-ui-icon icon="magnifyingGlass" iconSize="small" />
          </button>
        </div>
      </div>
      <query-quality-dropdown [query]="query" [show]="showQueryQuality"></query-quality-dropdown>
    </section>
  </lim-ui-modal-body>
</lim-ui-modal>
