<div class="error-page">
  <div class="error-page-container">
    <ng-template [ngIf]="isMobile === true" [ngIfElse]="desktopImg">
      <img src="../../assets/images/error-page/error-page-responsive.png" alt="404" class="error-page-image" />
    </ng-template>
    <ng-template #desktopImg>
      <img src="../../assets/images/error-page/error-page.png" alt="404" class="error-page-image" />
    </ng-template>
    <h1 class="error-page-title">Something went wrong...</h1>
    <p class="error-text">
      The page you were looking for doesn't exist, isn't available, or was loading incorrectly. Please try a new search.
    </p>
    <part-search-field></part-search-field>
    <a href="" class="return-home">Or Return to Home</a>
  </div>
</div>
