<section>
  <div class="test-settings">
    <div style="display: flex; gap: 4em">
      <div>
        <input type="text" [value]="query" (change)="setQuery($event)" class="query" placeholder="Search query..." />
        <button (click)="search()">Search</button>
      </div>
      <div>
        <h4>Vendors</h4>
        <ul class="checkbox-settings">
          <li *ngFor="let dataSource of dataSources; let i = index">
            <lim-ui-checkbox
              [model]="dataSource['enabled']"
              [label]="dataSource['name']"
              (modelChange)="toggleDataSource($event, i)"
            ></lim-ui-checkbox>
          </li>
        </ul>
      </div>
      <div>
        <h4>Pipelines</h4>
        <ul class="checkbox-settings">
          <li *ngFor="let pipeline of pipelines; let i = index">
            <lim-ui-checkbox
              [model]="pipeline['enabled']"
              [label]="pipeline['key']"
              (modelChange)="togglePipeline($event, i)"
            ></lim-ui-checkbox>
          </li>
        </ul>
      </div>
      <div>
        <h4>Timing - {{ timings.total_time / 1000 | currency : '' : '' : '1.2-3' }}s</h4>
        <div class="timing-container">
          <div class="legend-y">
            <div class="label-y" *ngFor="let timing of timings.timings">{{ timing['name'] }}</div>
          </div>
          <div class="bar-container">
            <div
              class="bar"
              *ngFor="let timing of timings.timings"
              [ngStyle]="{ 'left.px': timing.display_start, 'width.px': timing.display_width }"
            ></div>
          </div>
          <div></div>
          <div class="legend-x">
            <div class="label-x" *ngFor="let x of timingTicks; let seconds = index">{{ seconds }}</div>
          </div>
        </div>
      </div>

      <div>
        <h4>Cost - {{ cost.total_cost | currency : 'USD' : 'symbol-narrow' : '1.2-3' }}</h4>
        <div class="cost-container">
          <div class="cost-row" *ngFor="let row of cost.cost">
            <div class="cost-label">{{ row['name'] }}</div>
            <div class="cost-amount">{{ row['amount'] | currency : 'USD' : 'symbol-narrow' : '1.4-4' }}</div>
          </div>
          <div class="cost-row total">
            <div class="cost-label">Total</div>
            <div class="cost-amount">{{ cost.total_cost | currency : 'USD' : 'symbol-narrow' : '1.4-4' }}</div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <button (click)="deleteTest()">Delete</button>
    </div>
  </div>

  <div class="scroll-container">
    <div #itemsTable></div>
  </div>
</section>
