import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgClass, NgIf, DatePipe } from '@angular/common';
import { Subscription } from 'rxjs';

import { AuthService } from '../_services/auth.service';
import { FeatureService } from '../_services/feature.service';
import { NotificationService, NotificationType } from '../_services/notification.service';
import { isMobile } from '../_util/mobile.util';

import { ToastrService } from 'ngx-toastr';

import { PrimaryButtonComponent } from '@limblecmms/lim-ui';

import { CONFIG } from '../../environments/environment';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss'],
  standalone: true,
  imports: [FormsModule, NgClass, PrimaryButtonComponent, NgIf, DatePipe]
})
export class AccountComponent implements OnInit, OnDestroy {
  form: any = {
    firstName: null,
    lastName: null,
    companyName: null,
    email: null,
    password: null
  };
  user: any;

  private loggedInSubscriber?: Subscription;
  private authErrorSubscriber?: Subscription;
  private updateAccountSubscriber?: Subscription;
  private notificationSubscriber?: Subscription;
  private featuresSub?: Subscription;

  public isMobile: boolean = false;
  public showApiKey: boolean = false;
  public apiKey: string = '';
  public apiKeyLastRefreshed: Date | null = null;
  public apiDocUrl = `${CONFIG.API_URL}docs/search-docs`;

  constructor(
    private authService: AuthService,
    private notificationService: NotificationService,
    private toastrService: ToastrService,
    private featureService: FeatureService
  ) {
    this.toastrService.toastrConfig.preventDuplicates = true;
  }

  ngOnInit(): void {
    this.isMobile = isMobile();

    this.loggedInSubscriber = this.authService.isLoggedIn$.subscribe((isLoggedIn) => {
      if (isLoggedIn) {
        this.user = this.authService.getUser();
        this.apiKeyLastRefreshed = this.user.keyLastRefreshed;
        this.form = {
          firstName: this.user.firstName,
          lastName: this.user.lastName,
          companyName: this.user.customer.name,
          email: this.user.email,
          currentPassword: ''
        };
      }
    });

    this.authErrorSubscriber = this.authService.error$.subscribe((error) => {
      if (error) {
        this.notificationService.error(error);
      }
    });

    this.updateAccountSubscriber = this.authService.accountUpdated$.subscribe((result) => {
      this.notificationService.success('Account updated successfully!');
    });

    // Main app component subscription for notifications was removed, so we need to subscribe here
    this.notificationSubscriber = this.notificationService.notifications$.subscribe((notification) => {
      if (!notification?.type || !notification?.message) {
        return;
      }

      if (notification.type == NotificationType.Success) {
        this.toastrService.success(notification.message, undefined, {
          timeOut: 5000,
          closeButton: true
        });
      } else if (notification.type == NotificationType.Error) {
        this.toastrService.error(notification.message, undefined, {
          timeOut: 3000,
          closeButton: true
        });
      } else {
        this.toastrService.info(notification.message, undefined, {
          timeOut: 2000,
          closeButton: true
        });
      }
    });

    this.featuresSub = this.featureService.features$.subscribe((features) => {
      this.showApiKey = this.featureService.enabled('search-external-api');
    });
  }

  ngOnDestroy(): void {
    this.loggedInSubscriber?.unsubscribe();
    this.authErrorSubscriber?.unsubscribe();
    this.updateAccountSubscriber?.unsubscribe();
    this.notificationSubscriber?.unsubscribe();
    this.featuresSub?.unsubscribe();
  }

  onSubmit(): void {
    const { firstName, lastName, companyName, email, currentPassword, password, passwordConfirm } = this.form;

    if (password != passwordConfirm) {
      this.notificationService.error('New passwords do not match.');
      return;
    }

    this.authService.updateAccount(firstName, lastName, companyName, email, currentPassword, password);
  }

  createApiKey(): void {
    this.authService.createApiKey().subscribe({
      next: (data: any) => {
        this.apiKey = data.apiKey;
        this.notificationService.success('API key created successfully!');
      },
      error: (response) => {
        this.notificationService.error(response.error.message);
      }
    });
  }
}
