import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { inject } from '@angular/core';
import { map, Observable } from 'rxjs';
import { SearchService } from './search.service';

export const TitleResolver: ResolveFn<string> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
  searchService: SearchService = inject(SearchService)
): Observable<string> => searchService.query$.pipe(map((title: string): string => `Limble Search : ${title}`));
