import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgClass } from '@angular/common';
import type { AnimationItem } from 'lottie-web';
import { LottieComponent, AnimationOptions } from 'ngx-lottie';

/**
 * This component was inspired by this blog: https://dev.to/riapacheco/adding-animated-illustrations-to-an-angular-app-with-lottie-ngx-lottie-4j0o
 *
 */
type AnimationSizes = 'extra-small' | 'small' | 'medium-small' | 'medium' | 'large';

@Component({
  selector: 'app-animation',
  standalone: true,
  imports: [NgClass, LottieComponent],
  templateUrl: './animation.component.html',
  styleUrls: ['./animation.component.scss']
})
export class AnimationComponent {
  @Input() animationSize: AnimationSizes = 'small';

  @Output() readonly animationCreated = new EventEmitter();

  public options;

  public onAnimate(animationItem: AnimationItem): void {
    this.animationCreated.emit(animationItem);
  }
}
