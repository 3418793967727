<section>
  <div [class]="{ hidden: !show }" class="results">
    <h3 class="loading" *ngIf="!query || isLoading">
      Please provide more details to the search term.
      <img src="assets/images/loading-spinner-green.png" class="loading-spinner" *ngIf="isLoading" />
    </h3>
    <h3 *ngIf="query && !isLoading">
      Search Quality: <span class="quality" [class]="quality">{{ quality }}</span>
      <span>
        | We are
        <strong *ngIf="quality === 'low'">unlikely</strong>
        <strong *ngIf="quality !== 'low'">likely</strong>
        to find relevant results for your search term.</span
      >
    </h3>
    <ul class="metrics">
      <li class="metric" [class]="{ found: facets['part_number'] }">
        <lim-ui-icon class="icon" icon="check" [hidden]="!facets['part_number']"></lim-ui-icon>
        <lim-ui-icon class="icon" icon="xmark" [hidden]="facets['part_number']"></lim-ui-icon>
        <div class="name">Contains part number</div>
      </li>
      <li class="metric" [class]="{ found: facets['product_type'] }">
        <lim-ui-icon class="icon" icon="check" [hidden]="!facets['product_type']"></lim-ui-icon>
        <lim-ui-icon class="icon" icon="xmark" [hidden]="facets['product_type']"></lim-ui-icon>
        <div class="name">Contains product name</div>
      </li>
      <li class="metric" [class]="{ found: facets['brand'] }">
        <lim-ui-icon class="icon" icon="check" [hidden]="!facets['brand']"></lim-ui-icon>
        <lim-ui-icon class="icon" icon="xmark" [hidden]="facets['brand']"></lim-ui-icon>
        <div class="name">Contains brand name</div>
      </li>
      <li class="metric" [class]="{ found: facets['specifications'] }">
        <lim-ui-icon class="icon" icon="check" [hidden]="!facets['specifications']"></lim-ui-icon>
        <lim-ui-icon class="icon" icon="xmark" [hidden]="facets['specifications']"></lim-ui-icon>
        <div class="name">Contains other specifics (size, dimensions, etc)</div>
      </li>
    </ul>
  </div>
  <div class="query-quality-overlay" *ngIf="!isMobile" [class]="{ hidden: !show }"></div>
</section>
