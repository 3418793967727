import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgFor, NgIf } from '@angular/common';
import { isMobile, browserType } from '../_util/mobile.util';

import { MinimalIconButtonComponent } from '@limblecmms/lim-ui';
import { PartSearchFieldComponent } from '../part-search-field/part-search-field.component';

@Component({
  selector: 'extension-landing',
  templateUrl: './extension-landing.component.html',
  styleUrls: ['./extension-landing.component.scss'],
  standalone: true,
  imports: [NgIf, MinimalIconButtonComponent, NgFor, PartSearchFieldComponent]
})
export class ExtensionLanding implements OnInit, OnDestroy {
  public isMobile: boolean = false;
  public isSupportedBrowser: boolean = false;
  public browserType: string = browserType();

  constructor() {}

  ngOnInit(): void {
    this.isMobile = isMobile();
    this.isSupportedBrowser = this.browserType === 'chrome' || this.browserType === 'edge';
  }

  getBrowserName(): string {
    if (this.browserType === 'edge') {
      return 'Edge';
    } else {
      return 'Chrome';
    }
  }

  ngOnDestroy(): void {}
}
