import { Component, OnInit } from '@angular/core';
import { NgIf } from '@angular/common';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { faLocationDot } from '@fortawesome/pro-regular-svg-icons';

@Component({
  selector: 'delivery-location',
  standalone: true,
  imports: [NgIf, FontAwesomeModule],
  templateUrl: './delivery-location.component.html',
  styleUrl: './delivery-location.component.scss'
})
export class DeliveryLocationComponent implements OnInit {
  public displayZipCode: string | undefined = '';
  public faLocationDot = faLocationDot;

  ngOnInit() {
    const userLocation = localStorage.getItem('userLocation');
    const loc = userLocation ? JSON.parse(userLocation) : null;

    this.displayZipCode = loc?.geoDetails?.postcode;
  }
}
