<section>
  <header>
    <ng-container *ngIf="!isMobile; else responsiveHeader">
      <h2 *ngIf="!!items.length">Buying Options ({{ items.length }})</h2>
    </ng-container>
    <ng-template #responsiveHeader>
      <h2 *ngIf="!!items.length">Buying Options</h2>
    </ng-template>
    <nav *ngIf="!isMobile && !!items.length">
      <lim-ui-dropdown-button [label]="getSortByLabel()" [widthFitContent]="true">
        <div menu>
          <lim-ui-dropdown-text-item
            displayString="Total Price: Low to High"
            outputString="price_asc"
            (itemSelectedEvent)="sortItems('price_asc')"
          ></lim-ui-dropdown-text-item>
          <lim-ui-dropdown-text-item
            displayString="Total Price: High to Low"
            outputString="price_desc"
            (itemSelectedEvent)="sortItems('price_desc')"
          ></lim-ui-dropdown-text-item>
          <lim-ui-dropdown-text-item
            displayString="Unit Price: Low to High"
            outputString="price_per_desc"
            (itemSelectedEvent)="sortItems('price_per_asc')"
          ></lim-ui-dropdown-text-item>
          <lim-ui-dropdown-text-item
            displayString="Unit Price: High to Low"
            outputString="price_per_asc"
            (itemSelectedEvent)="sortItems('price_per_desc')"
          ></lim-ui-dropdown-text-item>
        </div>
      </lim-ui-dropdown-button>
    </nav>
  </header>
  <div *ngFor="let vendorItems of vendorGroupedItems; index as i">
    <vendor-grouped-items
      [vendorGroupedItems]="vendorItems"
      [selectedItem]="currentSelectedItem"
      [selectedVendorId]="selectedVendorId"
      [showOptions]="vendorGroupedItems.length == 1"
      (onItemSelect)="updateSelectedItem($event)"
      (unhideItem)="unhideItem($event)"
    >
    </vendor-grouped-items>
  </div>
</section>
<div class="section-break"></div>
