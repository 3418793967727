import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { ItemsService } from '../_services/items.service';

export const detailPageResolver: ResolveFn<string> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
  itemsService: ItemsService = inject(ItemsService)
) => {
  const { queryParams, params } = route;
  const { id } = params;
  const { group } = queryParams;

  return itemsService.getItem(id, group);
};
