<div id="app" [attr.app-version]="version">
  <nav [ngClass]="responsivePositioning ? 'nav-static' : 'nav-fixed'">
    <a routerLink="/" class="logo">
      <img src="/assets/images/limble-search-logo.svg" />
    </a>
    <part-search-field
      *ngIf="isLoggedIn && router.url !== '/' && router.url !== '/vendor-prefs' && router.url !== '/error' && !isMobile"
    ></part-search-field>
    <ng-container *ngIf="!isMobile; else showMobile">
      <div class="side-tab" *ngIf="isLoggedIn">
        <div class="link-container">
          <fa-icon [icon]="faPuzzlePiece"></fa-icon>
          <a routerLink="/extension">Extension</a>
        </div>
        <div class="link-container">
          <lim-ui-icon icon="userRegular" iconSize="small" class="account-icon"></lim-ui-icon>
          <lim-ui-dropdown #dropdown [hoverDropdown]="true" [widthFitContent]="true" [placement]="'bottom-start'">
            <div button #dropdownButton class="account">Account</div>
            <div menu>
              <lim-ui-dropdown-text-item
                [closeDropdownOnClick]="true"
                (click)="router.navigate(['/account'])"
                displayString="Edit Account"
              ></lim-ui-dropdown-text-item>
              <lim-ui-dropdown-text-item
                [closeDropdownOnClick]="true"
                (click)="router.navigate(['/'], { queryParams: { vendorPrefs: 1 } })"
                displayString="Vendor Preferences"
              ></lim-ui-dropdown-text-item>
              <lim-ui-dropdown-text-item
                class="logout"
                [closeDropdownOnClick]="true"
                (click)="logout()"
                displayString="Logout"
              ></lim-ui-dropdown-text-item>
            </div>
          </lim-ui-dropdown>
        </div>
      </div>
      <div class="links" *ngIf="(!isLoggedIn && router.url === '/') || router.url.includes('/explore')">
        <a routerLink="/login">Login</a>
        <a routerLink="/register" class="sign-up">
          {{ router.url.includes('/explore') ? 'Start Free Trial' : 'Sign Up' }}
        </a>
      </div>
    </ng-container>
    <ng-template #showMobile>
      <span
        class="hamburger-menu-wrapper"
        (click)="toggleMobileMenu()"
        *ngIf="router.url != '/login' && router.url != '/register'"
      >
        <lim-ui-icon icon="barsRegular" />
      </span>
    </ng-template>
  </nav>
  <div *ngIf="isMobile && showMobileMenu" [ngClass]="{ backdrop: showMobileMenu }" (click)="toggleMobileMenu()"></div>
  <div *ngIf="isMobile" class="mobile-side-menu" [ngClass]="{ 'show-side-menu': showMobileMenu }">
    <mobile-nav (hideMobileMenu)="toggleMobileMenu()"> </mobile-nav>
  </div>
  <search-complete-notification></search-complete-notification>
  <main [ngClass]="responsivePositioning ? 'main-static' : 'main-fixed'">
    <signup-flyout></signup-flyout>

    <page-banner class="trial-expired" *ngIf="userAccessStatus === userAccessStatusEnum.ExpiredTrial">
      <div icon>
        <fa-icon [icon]="clockIcon" />
      </div>
      <div content class="msg">
        Your free trial has ended. Upgrade today to always get the best price.
        <a routerLink="/extension" class="cta">Contact Sales</a>
      </div>
    </page-banner>

    <page-banner *ngIf="userAccessStatus === userAccessStatusEnum.Trial">
      <div icon>
        <fa-icon [icon]="clockIcon" />
      </div>
      <div content class="msg">
        Your free trial is ending in <b>{{ daysInTrialRemaining }}</b> days. Upgrade now before losing access.
        <a routerLink="/extension" class="cta">Contact Sales</a>
      </div>
    </page-banner>

    <page-banner
      *ngIf="
        (userAccessStatus === userAccessStatusEnum.Full || userAccessStatus === null) &&
        routeExtensionBannerEnabled() &&
        showExtensionBanner
      "
      (closedBanner)="handleBannerClose()"
    >
      <div icon>
        <div class="pill">New</div>
      </div>
      <div content class="msg">
        Use Limble Search on Any Website.
        <a routerLink="/extension" class="cta">Learn More</a>
      </div>
    </page-banner>

    <router-outlet></router-outlet>
    <!--TODO: Look into alternatives for this -add footer to accomodate IOS/safari browser address bar occupying viewport height at the bottom-->
    <footer *ngIf="isMobile && isIOS && (router.url.includes('results') || router.url.includes('details'))"></footer>
  </main>
</div>
