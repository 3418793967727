import { NgClass } from '@angular/common';
import { Component } from '@angular/core';
import { LottieComponent } from 'ngx-lottie';
import animation from '../../../assets/lottie/skeleton_loader_item.json';
import responsiveAnimation from '../../../assets/lottie/skeleton_responsive_loader_item.json';
import { AnimationComponent } from '../animation/animation.component';
import { isMobile } from '../../_util/mobile.util';

@Component({
  selector: 'item-loading-animation',
  templateUrl: 'item-loading-animation.html',
  styleUrls: ['item-loading-animation.scss'],
  standalone: true,
  imports: [NgClass, LottieComponent]
})
export class ItemLoadingAnimation extends AnimationComponent {
  public isMobile: boolean = isMobile();

  public override options = {
    animationData: this.isMobile ? responsiveAnimation : animation
  };
}
