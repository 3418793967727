import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { NgClass, NgIf } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { mergeMap, Subscription } from 'rxjs';

import { AuthService } from '../_services/auth.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { isMobile } from '../_util/mobile.util';

import { IconComponent } from '@limblecmms/lim-ui';
import { LogoAnimation } from '../animations/logo-animation/logo-animation.component';

import { CONFIG } from '../../environments/environment';

@Component({
  selector: 'register-form',
  templateUrl: './register-form.component.html',
  styleUrls: ['./register-form.component.scss'],
  standalone: true,
  imports: [FormsModule, NgClass, NgIf, RouterLink, LogoAnimation, IconComponent]
})
export class RegisterFormComponent implements OnInit, OnDestroy {
  @Output() loginClick = new EventEmitter();

  registerForm: any = {
    firstName: null,
    lastName: null,
    companyName: null,
    email: null,
    password: null,
    phoneNumber: null
  };

  public loading: boolean = false;

  registerDebounce: boolean = false;
  registrationError: boolean = false;
  errorMsg: string = '';
  token: string | undefined;

  public passwordVisible: boolean = false;
  public isMobile: boolean = false;

  constructor(
    private authService: AuthService,
    private router: Router,
    private recaptchaV3Service: ReCaptchaV3Service,
    private http: HttpClient
  ) {}

  ngOnInit(): void {
    this.checkHealth();
    this.isMobile = isMobile();

    this.registerDebounce = false;
  }

  ngOnDestroy(): void {}

  async checkHealth(): Promise<any> {
    if (
      localStorage.getItem('healthCheck') &&
      Date.now() - parseInt(localStorage.getItem('healthCheck') || '') < 1000 * 60 * 5
    ) {
      return;
    } else {
      this.http.get(CONFIG.API_URL + 'healthPing').subscribe(
        (response) => console.log('LimSearch Online:', response),
        (error) => console.error('Health check failed:', error)
      );
      localStorage.setItem('healthCheck', Date.now().toString());
    }
  }

  onSubmit(): void {
    if (this.registerDebounce) {
      return;
    }
    this.registerDebounce = true;
    this.loading = true;

    this.recaptchaV3Service
      .execute('register')
      .pipe(mergeMap((token: string) => this.authService.verifyRecaptcha(token)))
      .subscribe({
        next: (data) => {
          console.log('Recaptcha verified');
          if (data.success === false) {
            this.registrationError = true;
            this.errorMsg = '\n Recaptcha Verification failed. Please try again.';
            return;
          }

          const { firstName, lastName, companyName, email, password, phoneNumber } = this.registerForm;

          this.authService.register(firstName, lastName, companyName, email, password, phoneNumber).subscribe({
            next: (data) => {
              this.registrationError = false;
              this.registerDebounce = false;
              this.authService.login(email, password);
            },
            error: (err) => {
              this.loading = false;
              this.registrationError = true;
              this.errorMsg = err.error.message;
              this.registerDebounce = false;
            }
          });
        },

        error: (err) => {
          this.loading = false;
          this.registrationError = true;
          this.registerDebounce = false;
          this.errorMsg = err.error.message;
        }
      });
  }

  hideShowPassword(): void {
    this.passwordVisible = !this.passwordVisible;
  }
}
