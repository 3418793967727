<section>
  <div class="login-form-container" *ngIf="loading === false && !showForgotPasswordForm">
    <h1 class="login-banner">Log in to view results</h1>
    <form name="loginForm" (ngSubmit)="f.form.valid && login()" #f="ngForm" novalidate>
      <div class="form-group">
        <label for="email" class="input-labels">Email Address</label>
        <input
          type="text"
          class="form-control"
          name="email"
          [(ngModel)]="loginForm.email"
          required
          #email="ngModel"
          [ngClass]="{ 'is-invalid': f.submitted && email.errors }"
        />
        <div *ngIf="email.errors && f.submitted" class="invalid-feedback">Email is required!</div>
      </div>
      <div class="form-group hoverboundary">
        <span>
          <label for="password" class="input-labels">Password</label>
          <input
            type="password"
            class="form-control"
            name="password"
            [(ngModel)]="loginForm.password"
            required
            minlength="6"
            #password="ngModel"
            [ngClass]="{ 'is-invalid': f.submitted && password.errors }"
            id="password"
          />
        </span>
        <span class="unhideOnHover">
          <lim-ui-icon
            [icon]="showPassword ? eyeIcon : eyeSlashIcon"
            class="unhideOnHoverIcon"
            iconSize="small"
            (click)="togglePasswordVisibility()"
          ></lim-ui-icon>
        </span>
      </div>

      <div class="password-tools">
        <a (click)="showForgotPasswordForm = true">Forgot Password?</a>
      </div>

      <button type="submit" class="login-button">Log In</button>

      <div class="error-container" *ngIf="authService.error$ | async">
        {{ authService.error$ | async }}
      </div>
      <div class="signup-section">
        <span>Don’t have an account yet? </span>
        <a (click)="signupClick()">Sign Up</a>
      </div>
      <div class="legal-terms-section">
        By clicking "Log In" you agree to Limble's
        <a href="https://limblecmms.com/terms-of-service.html" target="_blank">Terms Of Service</a>
        and
        <a href="https://limblecmms.com/privacy.html" target="_blank"> Privacy Policy </a>
      </div>
    </form>
  </div>
  <div class="login-form-container" *ngIf="showForgotPasswordForm">
    <h1 class="login-banner">Reset your password</h1>
    <form name="forgotPasswordForm" (ngSubmit)="f.form.valid && forgotPassword()" #f="ngForm" novalidate>
      <div class="form-group">
        <label for="email">Email Address</label>
        <input
          type="text"
          class="form-control"
          name="email"
          [(ngModel)]="forgotPasswordForm.email"
          required
          #email="ngModel"
          [ngClass]="{ 'is-invalid': f.submitted && email.errors }"
        />
      </div>
      <button type="submit" class="login-button">Send Recovery Email</button>
      <button (click)="showForgotPasswordForm = false" class="login-button forgot-password-button">Back to Login</button>
    </form>
  </div>
  <div class="child-container" *ngIf="loading === true">
    <div class="logo-animation-container">
      <logo-animation [animationSize]="'large'" />
    </div>
  </div>
</section>
