//Adopted from webApp util with some modifications made for search frontend responsive.

import type { Observable } from 'rxjs';
import { BehaviorSubject, debounce, fromEvent, timer } from 'rxjs';

export function isMobile(): boolean {
  return isPhoneScreenSize() || isMobileBrowser();
}

export function isMobileBrowser(): boolean {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

function isScreenSizeSmallerThanOrEqual(screenSize): boolean {
  // console.log("window.innerWidth: ", window.innerWidth, "screenSize: ", screenSize);
  return window.innerWidth <= screenSize;
}

export function isPhoneScreenSize(): boolean {
  return isScreenSizeSmallerThanOrEqual(480);
}

export function browserType(): string {
  if (window.navigator.userAgent.indexOf('Edg') > -1) {
    return 'edge';
  } else if (window.navigator.userAgent.indexOf('Chrom') > -1) {
    return 'chrome';
  } else if (window.navigator.userAgent.indexOf('Mozilla') > -1) {
    return 'mozilla';
  }

  return 'other';
}

export function isTabletOrSmallerScreenSize(): boolean {
  return isScreenSizeSmallerThanOrEqual(1170);
}

export function isIOSUserAgent(): boolean {
  const userAgent = window.navigator.userAgent.toLowerCase();
  return isMobile() && /iphone|ipad| macintosh | mac| ipod/.test(userAgent);
}

function getScreenSizeObservable(screenSize: () => boolean): Observable<boolean> {
  const isPhoneScreenSize$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(screenSize());

  fromEvent(window, 'resize')
    .pipe(debounce(() => timer(200))) // debounce resize event so we don't cause too many rerenders
    .subscribe(() => {
      isPhoneScreenSize$.next(screenSize());
    });

  return isPhoneScreenSize$.asObservable();
}

export const isPhoneScreenSize$ = getScreenSizeObservable(isPhoneScreenSize);
export const isTabletOrSmallerScreenSize$ = getScreenSizeObservable(isTabletOrSmallerScreenSize);
