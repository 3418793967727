import { NgClass } from '@angular/common';
import { Component } from '@angular/core';
import { LottieComponent } from 'ngx-lottie';
import animation from '../../../assets/lottie/Limble Logo NAVY.json';
import { AnimationComponent } from '../animation/animation.component';

@Component({
  selector: 'logo-animation',
  templateUrl: '../animation/animation.component.html',
  styleUrls: ['../animation/animation.component.scss'],
  standalone: true,
  imports: [NgClass, LottieComponent]
})
export class LogoAnimation extends AnimationComponent {
  public override options = {
    animationData: animation
  };
}
