<div *ngIf="visible" class="modal-backdrop" (click)="hide()"></div>
<section *ngIf="visible" class="signup-modal">
  <header>
    <img src="/assets/images/limble-search-logo.svg" class="logo" />
    <fa-icon class="close" [icon]="faXmarkLarge" size="lg" (click)="hide()"></fa-icon>
  </header>
  <div class="flyout-container">
    <register-form (loginClick)="hide(); router.navigate(['/login'])"></register-form>
  </div>
</section>
