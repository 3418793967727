import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { NgIf } from '@angular/common';

@Component({
  selector: 'shipping-details',
  standalone: true,
  imports: [NgIf],
  templateUrl: './shipping-details.component.html',
  styleUrl: './shipping-details.component.scss'
})
export class ShippingDetailsComponent implements OnInit, OnChanges {
  public shippingDetails: string = '';
  public availabilityDetails: string = '';
  public shippingDot: string = '';
  public itemShippingTime: any;
  public itemAvailability: boolean = false;

  @Input() item;

  ngOnInit(): void {
    this.itemShippingTime = this.item?.shipping_days;
    this.itemAvailability = this.item?.inStock;

    this.updateShippingDetails(this.itemShippingTime, this.itemAvailability);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.itemShippingTime = this.item?.shipping_days;
    this.itemAvailability = this.item?.inStock;
    this.updateShippingDetails(this.itemShippingTime, this.itemAvailability);
  }

  updateShippingDetails(shippingTime: any, availability: boolean): void {
    if (availability) {
      if (shippingTime) {
        if (shippingTime >= 1) {
          this.availabilityDetails = `In stock, ships in`;
          this.shippingDetails = `${shippingTime} ${shippingTime > 1 ? 'days' : 'day'}`;
          this.shippingDot = `${shippingTime > 3 ? '#F29422' : '#289E49'}`;
        }
      } else {
        this.availabilityDetails = `In stock`;
        this.shippingDetails = '';
        this.shippingDot = '#F29422';
      }
    } else {
      if (shippingTime && shippingTime !== null) {
        this.availabilityDetails = `Ships in `;
        this.shippingDetails = `${shippingTime} ${shippingTime > 1 ? 'days' : 'day'}`;
        this.shippingDot = `${shippingTime >= 3 ? '#F29422' : '#289E49'}`;
      }
      // else{
      //   this.availabilityDetails = `Shipping details unavailable`;
      //   this.shippingDot = '#B3B3B3';
      // }
      else {
        this.availabilityDetails = '';
        this.shippingDetails = '';
        this.shippingDot = '';
      }
    }
  }
}
