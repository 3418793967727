import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { CommonModule, NgClass, NgStyle } from '@angular/common';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { Subscription } from 'rxjs';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faArrowTrendUp } from '@fortawesome/pro-regular-svg-icons';
import { faSparkles, faCircleDollar, faClock } from '@fortawesome/pro-solid-svg-icons';

import { HeapService } from '../_services/heap.service';
import { SearchService } from '../_services/search.service';
import { AuthService } from '../_services/auth.service';
import { FeatureService } from '../_services/feature.service';

import { isMobile } from '../_util/mobile.util';
import { vendorLogos, vendorLogosResponsive, customerReviews, popularItems } from '../_util/constants';

import { IconComponent, ModalService as LimUiModal } from '@limblecmms/lim-ui';
import { PartSearchFieldComponent } from '../part-search-field/part-search-field.component';
import { QueryQualityDropdownComponent } from '../query-quality-dropdown/query-quality-dropdown';
import { PartSearchModalComponent } from '../part-search-modal/part-search-modal.component';

import { CONFIG } from '../../environments/environment';
import { VendorPrefsComponent } from '../vendor-prefs/vendor-prefs';

@Component({
  selector: 'app-limble-search',
  templateUrl: './limble-search.component.html',
  styleUrls: ['./limble-search.component.scss'],
  standalone: true,
  imports: [
    IconComponent,
    FormsModule,
    CommonModule,
    PartSearchFieldComponent,
    QueryQualityDropdownComponent,
    FontAwesomeModule,
    RouterLink,
    NgStyle,
    NgClass,
    PartSearchModalComponent,
    VendorPrefsComponent
  ]
})
export class LimbleSearchComponent implements OnInit, OnDestroy {
  public query?: string;
  public isLoggedIn: Boolean = false;
  public health?: any;
  public searchInput = '';
  public showQueryQuality: boolean = false;
  public showVendorPrefs: boolean = false;
  public arrowTrendUp = faArrowTrendUp;
  public sparkles = faSparkles;
  public circleDollar = faCircleDollar;
  public clock = faClock;
  public popularItems = popularItems;
  public customerReviews = customerReviews;
  public vendorLogos = vendorLogos;
  public vendorLogosResponsive = vendorLogosResponsive;

  public page: number = 0;
  public pageSize: number = 8;
  public pages = 1;
  public pagedVendors: any = [];
  public vendorPageStartIndex: number = 0;

  public isMobile: boolean = false;
  public authSub?: Subscription;
  public querySub?: Subscription;

  constructor(
    private authService: AuthService,
    private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    public searchService: SearchService,
    public heapService: HeapService,
    public modalService: LimUiModal,
    private readonly featureService: FeatureService
  ) {}

  ngOnInit(): void {
    this.isLoggedIn = this.authService.isLoggedIn();
    this.isMobile = isMobile();

    this.checkHealth();
    this.findZipCode();

    this.authSub = this.authService.isLoggedIn$.subscribe((isLoggedIn) => {
      this.isLoggedIn = isLoggedIn;
      if (isLoggedIn) {
        this.authService.getUser();
        this.featureService.getFeatures();
      }
    });

    this.querySub = this.searchService.query$.subscribe((query: string) => {
      this.query = query;
    });

    let pageVendorInterval;
    if (pageVendorInterval) clearInterval(pageVendorInterval);

    //simulate pagination of vendors for responsive
    if (this.isMobile) {
      // this.pageSize = 9;
      this.setPageVendors();
      pageVendorInterval = setInterval(() => {
        this.page++;
        // if (this.page > this.pages) this.page = 1;
        this.setPageVendors();
      }, 3000);
    } else {
      this.pagedVendors = this.vendorLogos;
      if (pageVendorInterval) {
        clearInterval(pageVendorInterval);
      }
    }

    // Stop any active searches
    this.searchService.reset();

    this.route.queryParamMap.subscribe((params) => {
      // Scroll vendor prefs into view
      if (params.get('vendorPrefs')) {
        this.showVendorPrefs = true;

        setTimeout(() => {
          const vendorPrefs = document.getElementById('vendor-prefs');

          if (vendorPrefs) {
            vendorPrefs.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }

          // Remove the query param from the URL, so if the link is clicked again without refreshing
          // the vendor prefs window will be shown again
          this.router.navigate([], {
            queryParams: { vendorPrefs: null },
            queryParamsHandling: 'merge'
          });
        }, 100);
      }
    });
  }

  async checkHealth(): Promise<any> {
    if (
      localStorage.getItem('healthCheck') &&
      Date.now() - parseInt(localStorage.getItem('healthCheck') || '') < 1000 * 60 * 5
    ) {
      return;
    } else {
      this.http.get(CONFIG.API_URL + 'healthPing').subscribe(
        (response) => console.log('LimSearch Online:', response),
        (error) => console.error('Health check failed:', error)
      );
      localStorage.setItem('healthCheck', Date.now().toString());
    }
  }

  findZipCode() {
    return new Promise<void>((resolve, reject) => {
      try {
        let userLocation = localStorage.getItem('userLocation');
        let locationAge = localStorage.getItem('userLocationAge');
        let userLocObj = {
          geoDetails: null,
          baseDetails: null
        };
        if (!userLocation || !locationAge || Date.now() - parseInt(locationAge) > 1000 * 60 * 15) {
          fetch(`https://api.geoapify.com/v1/ipinfo?apiKey=${CONFIG.geoKey}`)
            .then((response) => response.json())
            .then((data) => {
              userLocObj.baseDetails = data;
              const lat = data.location.latitude;
              const lon = data.location.longitude;
              return fetch(
                `https://api.geoapify.com/v1/geocode/reverse?lat=${lat}&lon=${lon}&format=json&apiKey=${CONFIG.geoKey}`
              );
            })
            .then((response) => response.json())
            .then((ipData) => {
              userLocObj.geoDetails = ipData.results[0];
              localStorage.setItem('userLocation', JSON.stringify(userLocObj));
              localStorage.setItem('userLocationAge', Date.now().toString());
              resolve();
            })
            .catch((e) => {
              console.error('Error fetching user location: ', e);
              reject(e);
            });
        } else {
          resolve();
        }
      } catch (e) {
        console.error('Error finding user location: ', e);
        reject(e);
      }
    });
  }

  async search(): Promise<any> {
    this.showQueryQuality = false;
    await this.searchService.setSearchQuery(this.query || '');
    this.heapService.trackEvent('search', { query: this.query });
    if (this.isLoggedIn) {
      this.router.navigate(['/searching'], {
        skipLocationChange: true,
        replaceUrl: true
      });
    } else {
      this.router.navigate(['/login'], {
        skipLocationChange: true,
        replaceUrl: true
      });
    }
  }

  async performSampleSearch(): Promise<any> {
    localStorage.setItem('searchQuery', this.searchInput || '');
    if (this.isLoggedIn) {
      this.searchService.setSearchQuery(this.searchInput || '');
      this.router.navigate(['/searching'], {
        skipLocationChange: true,
        replaceUrl: true
      });
    } else {
      window.location.replace('/login');
    }
  }

  searchItem(itemPartNumber: string, itemTitle: string) {
    this.searchInput = `${itemPartNumber} ${itemTitle}`;
    this.performSampleSearch();
  }

  setPageVendors() {
    //pages needed only for responsive vendorLogos
    //pagination for even number of vendors per page
    // this.pages = Math.ceil(this.vendorLogosResponsive.length / this.pageSize);
    // this.pagedVendors = this.vendorLogosResponsive.slice((this.page - 1) * this.pageSize, this.page * this.pageSize);

    //pagination for uneven number of vendors per page
    if (this.vendorPageStartIndex === this.vendorLogosResponsive.length) {
      this.vendorPageStartIndex = 0;
      this.page = 0;
    }
    //add more vendors per page for mobile
    const vendorsPerPage = [9, 8, 5];
    this.pagedVendors = this.vendorLogosResponsive.slice(
      this.vendorPageStartIndex,
      this.vendorPageStartIndex + vendorsPerPage[this.page]
    );
    this.vendorPageStartIndex += vendorsPerPage[this.page];
  }

  async openPartSearchModal(): Promise<any> {
    const activePartSearchModal = this.modalService.getActiveModal();
    if (activePartSearchModal !== null) {
      return activePartSearchModal;
    }
    const modalRef = this.modalService.open(PartSearchModalComponent);
    const modalResult = await modalRef.result;
    return modalResult;
  }

  async showQueryQualityDropdown() {
    if (this.isMobile) {
      this.showQueryQuality = false;
      const modalRef = await this.openPartSearchModal();
      if (modalRef === false) {
        return;
      }
      await this.searchService.setSearchQuery(modalRef);
      await this.search();
    } else {
      this.showQueryQuality = true;
    }
  }

  ngOnDestroy(): void {
    this.authSub?.unsubscribe();
    this.querySub?.unsubscribe();
  }
}
