import { Injectable } from '@angular/core';
import { CONFIG } from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class HeapService {
  private readonly window: any = window;

  public constructor() {}

  public async load(): Promise<void> {
    if (CONFIG.API_URL.includes('localhost')) return;
    const heapModule = await import('../../assets/js/heap');
    heapModule.initializeHeap();
    const win: any = window;
    if (window.location.hostname.includes('search.limble.com')) {
      win.heap.load('1180072101');
    } else if (window.location.hostname.includes('partosphere.limblestaging.com')) {
      win.heap.load('2914399438');
    }
  }

  public identifyUser(user): void {
    if (!this.window.heap) return;
    this.window.heap.addUserProperties({
      userID: user.id,
      userEmail: user.email,
      companyID: user.customer.id,
      companyName: user.customer.name
    });
    this.window.heap.identify(user.email);
  }

  public trackEvent(event: string, properties: any): void {
    if (!this.window.heap) return;
    this.window.heap.track(event, properties);
  }
}
