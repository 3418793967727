<ng-container *ngIf="!isMobile; else responsiveVendorGroupedItems">
  <ul class="items" *ngIf="seeAllToggle">
    <li
      *ngFor="let item of vendorGroupedItems; index as j"
      class="item-container"
      (click)="selectItem($event, item)"
      [ngClass]="{ selected: item.vendorId == currentSelectedVendor && item.id === currentSelectedItem.id }"
    >
      <div class="item-details" [ngClass]="{ 'hide-item': item.user_hidden === true }">
        <div class="item" [attr.data-item-id]="item.id">
          <div class="vendor-logo">
            <img [src]="item.vendor.logoUrl" alt="Vendor Logo" *ngIf="j == 0" />
          </div>
          <div [ngClass]="item.title.length > 70 ? 'title' : 'single-line-title'">
            {{ item.title }}
          </div>
          <div class="stock-details">
            <shipping-details [item]="item"></shipping-details>
          </div>
          <div class="label-container" *ngIf="showLabelControls">
            <button
              (click)="setLabel(item.id, 'vote', 1); $event.stopPropagation()"
              [class]="{ selected: searchService.getItemLabel(item.id, 'vote') == 1 }"
            >
              <img src="assets/images/thumbs-up-icon.svg" />
            </button>
            <button
              (click)="setLabel(item.id, 'vote', -1); $event.stopPropagation()"
              [class]="{ selected: searchService.getItemLabel(item.id, 'vote') == -1 }"
            >
              <img src="assets/images/thumbs-down-icon.svg" />
            </button>
          </div>
          <div class="price">
            <div class="total">
              {{ item.price | currency }}
            </div>
            <div class="per" *ngIf="item.pricePer !== item.price">({{ item.pricePer | currency }}/count)</div>
          </div>
        </div>
        <div class="line-break" *ngIf="vendorGroupedItems.length > 1 && j == 0"></div>
        <div
          class="controls"
          *ngIf="vendorGroupedItems.length > 1 && j == 0"
          (click)="toggleSeeAll(); $event.stopPropagation()"
        >
          <div class="toggle-items">
            <span [hidden]="seeAllToggle">See All</span>
            <span [hidden]="!seeAllToggle">Hide All</span>
          </div>
          <div class="per-vendor-items">
            <span>{{ vendorGroupedItems.length }} options</span>
          </div>
        </div>
      </div>
    </li>
  </ul>

  <ul class="items" *ngIf="!seeAllToggle">
    <li
      class="item-container"
      (click)="selectItem($event, vendorGroupedItems[0])"
      [ngClass]="{ selected: vendorGroupedItems[0].vendorId == currentSelectedVendor }"
    >
      <div class="item-details">
        <div class="item" [attr.data-item-id]="vendorGroupedItems[0].id">
          <div class="vendor-logo">
            <img [src]="vendorGroupedItems[0].vendor.logoUrl" alt="Vendor Logo" />
          </div>
          <div [ngClass]="vendorGroupedItems[0].title.length > 70 ? 'title' : 'single-line-title'">
            {{ vendorGroupedItems[0].title }}
          </div>
          <div class="stock-details">
            <shipping-details [item]="vendorGroupedItems[0]"></shipping-details>
          </div>
          <div class="label-container" *ngIf="showLabelControls">
            <button
              (click)="setLabel(vendorGroupedItems[0].id, 'vote', 1); $event.stopPropagation()"
              [class]="{ selected: searchService.getItemLabel(vendorGroupedItems[0].id, 'vote') == 1 }"
            >
              <img src="assets/images/thumbs-up-icon.svg" />
            </button>
            <button
              (click)="setLabel(vendorGroupedItems[0].id, 'vote', -1); $event.stopPropagation()"
              [class]="{ selected: searchService.getItemLabel(vendorGroupedItems[0].id, 'vote') == -1 }"
            >
              <img src="assets/images/thumbs-down-icon.svg" />
            </button>
          </div>
          <div class="price">
            <div class="total">
              {{ vendorGroupedItems[0].price | currency }}
            </div>
            <div class="per" *ngIf="vendorGroupedItems[0].pricePer !== vendorGroupedItems[0].price">
              ({{ vendorGroupedItems[0].pricePer | currency }}/count)
            </div>
          </div>
        </div>
        <div class="line-break" *ngIf="vendorGroupedItems.length > 1"></div>
        <div class="controls" *ngIf="vendorGroupedItems.length > 1" (click)="toggleSeeAll(); $event.stopPropagation()">
          <div class="toggle-items">
            <span [hidden]="seeAllToggle">See All</span>
            <span [hidden]="!seeAllToggle">Hide All</span>
          </div>
          <div class="per-vendor-items">
            <span>{{ vendorGroupedItems.length }} options</span>
          </div>
        </div>
      </div>
    </li>
  </ul>
</ng-container>
<ng-template #responsiveVendorGroupedItems>
  <ul class="responsive-items" *ngIf="seeAllToggle">
    <li
      *ngFor="let item of vendorGroupedItems; index as j"
      class="item-container"
      (click)="selectItem($event, item)"
      [ngClass]="{ selected: item.vendorId == currentSelectedVendor && item.id === currentSelectedItem.id }"
    >
      <div class="responsive-item" [attr.data-item-id]="item.id">
        <div class="main-details">
          <div class="title">
            {{ item.title }}
          </div>
          <div class="price">
            <div class="total">
              {{ item.price | currency }}
            </div>
            <div class="per" *ngIf="item.pricePer !== item.price">({{ item.pricePer | currency }}/count)</div>
          </div>
        </div>
        <div class="other-options" *ngIf="j == 0">
          <div class="vendor-logo">
            <img [src]="item.vendor.logoUrl" alt="Vendor Logo" />
          </div>
          <div class="controls" *ngIf="vendorGroupedItems.length > 1" (click)="toggleSeeAll(); $event.stopPropagation()">
            <div class="toggle-items">
              <span [hidden]="seeAllToggle">See {{ vendorGroupedItems.length }} options</span>
              <span [hidden]="!seeAllToggle">Hide {{ vendorGroupedItems.length }} options</span>
            </div>
          </div>
        </div>
      </div>
    </li>
  </ul>

  <ul class="responsive-items" *ngIf="!seeAllToggle">
    <li
      class="item-container"
      (click)="selectItem($event, vendorGroupedItems[0])"
      [ngClass]="{ selected: vendorGroupedItems[0].vendorId == currentSelectedVendor }"
    >
      <div class="responsive-item" [attr.data-item-id]="vendorGroupedItems[0].id">
        <div class="main-details">
          <div class="title">
            {{ vendorGroupedItems[0].title }}
          </div>
          <div class="price">
            <div class="total">
              {{ vendorGroupedItems[0].price | currency }}
            </div>
            <div class="per" *ngIf="vendorGroupedItems[0].pricePer !== vendorGroupedItems[0].price">
              ({{ vendorGroupedItems[0].pricePer | currency }}/count)
            </div>
          </div>
        </div>
        <div class="other-options">
          <div class="vendor-logo">
            <img [src]="vendorGroupedItems[0].vendor.logoUrl" alt="Vendor Logo" />
          </div>
          <div class="controls" *ngIf="vendorGroupedItems.length > 1" (click)="toggleSeeAll(); $event.stopPropagation()">
            <div class="toggle-items">
              <span [hidden]="seeAllToggle">See {{ vendorGroupedItems.length }} options</span>
              <span [hidden]="!seeAllToggle">Hide {{ vendorGroupedItems.length }} options</span>
            </div>
          </div>
        </div>
      </div>
    </li>
  </ul>
</ng-template>
