import { Component, OnInit, OnDestroy, HostListener, Renderer2, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgIf, NgClass, Location } from '@angular/common';
import { IconComponent } from '@limblecmms/lim-ui';
import { combineLatest, mergeMap } from 'rxjs';

import { SimilarItemsComponent } from '../similar-items/similar-items';
import { ItemHiddenNotificationComponent } from '../custom-notifications/item-hidden/item-hidden-notification.component';
import { ItemDetailsComponent } from '../item-details/item-details.component';
import { PartSearchMatchesComponent } from '../part-search-matches/part-search-matches.component';

import { SearchService } from '../_services/search.service';
import { NotificationService } from '../_services/notification.service';
import { ItemsService } from '../_services/items.service';
import { Subscription } from 'rxjs';
import { isMobile } from '../_util/mobile.util';
import { ItemGroupMatch } from '../_types/attributeGrouping';

@Component({
  selector: 'details-view',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss'],
  standalone: true,
  imports: [
    IconComponent,
    SimilarItemsComponent,
    NgIf,
    NgClass,
    ItemHiddenNotificationComponent,
    ItemDetailsComponent,
    PartSearchMatchesComponent
  ]
})
export class DetailsComponent implements OnInit, OnDestroy {
  public selectedItem: any | undefined;
  public hasMatchedItems: boolean = false;
  public currentSelectedItem: any = {};
  public resultsPageQueryParams: any = {};
  public itemHiddenNotification: any | null = null;
  public isMobile: boolean = false;
  public match: ItemGroupMatch | undefined;
  public hasSelectedItem: boolean = false;
  private searchHistoryId?: number;
  private detailViewCount: number = 0;

  public selectedItemSub?: Subscription;
  public notificationSub?: Subscription;

  @HostListener('window:scroll', ['$event'])
  isSimilarItemsInView(event: any) {
    const similarItems = document.querySelector('similar-items');
    if (similarItems) {
      const rect = similarItems.getBoundingClientRect();
      if (rect.top < window.innerHeight && rect.bottom > 0) {
        this.heroItemPositioning(true);
      } else {
        this.heroItemPositioning(false);
      }
    }
  }

  constructor(
    public searchService: SearchService,
    public router: Router,
    public route: ActivatedRoute,
    public notificationService: NotificationService,
    public itemsService: ItemsService,
    private renderer: Renderer2,
    private location: Location,
    private el: ElementRef
  ) {
    this.isMobile = isMobile();

    //constructing the previous results page url to navigate back to
    const currentNavigation = this.router.getCurrentNavigation();
    const previousNav = currentNavigation?.previousNavigation;
    const finalUrl = previousNav?.finalUrl?.toString();

    //TODO: disabling hardlink details page to trigger a new search for now. Will revisit this later
    //hardlink details page to trigger a new search
  }

  ngOnInit() {
    this.route.data.subscribe(({ itemResponse }) => {
      this.detailViewCount = this.detailViewCount + 1;
      const item = itemResponse;
      this.selectedItem = item;
      this.searchHistoryId = item.searchHistoryId;
      this.currentSelectedItem = item;
      this.searchService.setDetailsPageSelectedItem(this.currentSelectedItem);

      if (item.match) {
        this.hasMatchedItems = true;
        const filteredMatchItems = this.searchService.filterResults(item.match.items);
        item.match.items = filteredMatchItems;
      }

      this.match = item.match;

      if (item) {
        this.hasSelectedItem = true;
      }
      this.searchService.stopProgressBar();
    });

    // Listen for the scroll event on the specific element
    this.notificationSub = this.notificationService.hiddenItemNotification$.subscribe((itemHiddenNotification) => {
      this.itemHiddenNotification = itemHiddenNotification;
    });

    const element = document.querySelector('main');
    this.renderer.listen(element, 'scroll', (event) => {
      this.isSimilarItemsInView(event);
    });
  }

  navigateToResults() {
    this.searchService.setDetailsPageSelectedItem(this.currentSelectedItem);
    if (this.searchService._isMarketingMode.getValue()) {
      this.location.back();
      return;
    }

    this.location.historyGo(-1 * this.detailViewCount);
  }

  updateSelectedItem(event: any) {
    this.currentSelectedItem = event;
  }
  public responsiveHeroItemSticky: boolean = false;
  heroItemPositioning(visibility: boolean) {
    if (!this.isMobile) {
      return;
    }
    const heroItem = document.getElementById('hero-section');
    if (visibility) {
      heroItem?.style.setProperty('position', 'static');
      this.responsiveHeroItemSticky = false;
    } else {
      heroItem?.style.setProperty('position', 'sticky');
      this.responsiveHeroItemSticky = true;
    }
  }

  ngOnDestroy() {
    this.selectedItemSub?.unsubscribe();
    this.notificationSub?.unsubscribe();
  }
}
