<div class="main-container">
  <div class="child-container" *ngIf="!isMobile">
    <div class="login-cover-image">
      <div class="image-text">Confidently find the cheapest part in a fraction of the time</div>
    </div>
  </div>
  <div class="child-container">
    <div class="sign-up-container"><register-form (loginClick)="router.navigate(['/login'])"></register-form></div>
  </div>
</div>
