<div class="main-container">
  <div class="child-container" *ngIf="!isMobile">
    <div class="login-cover-image">
      <div class="image-text">Confidently find the cheapest part in a fraction of the time</div>
    </div>
  </div>
  <div class="child-container">
    <login-form (signupClickEvent)="router.navigate(['/register'])"></login-form>
  </div>
</div>
