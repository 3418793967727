<div class="radio-button-container" [attr.data-log]="dataLogLabel">
  <input
    type="radio"
    [id]="inputID"
    [name]="groupID"
    [checked]="checked"
    [disabled]="disabled"
    (change)="handleRadioClick()"
  />
  <label
    *ngIf="label"
    [for]="inputID"
    [ngClass]="{ 'max-width': labelMaxWidth }"
    (click)="handleRadioClick()"
    [limbleHtml]="label"
  >
  </label>
  <lim-ui-icon *ngIf="tooltip !== undefined" icon="circleQuestionRegular" limUiTooltip="{{ tooltip }}" iconSize="small" />
</div>
