<section>
  <a (click)="settingsVisible = !settingsVisible" class="settings-link">Default Settings</a>
  <div class="settings" *ngIf="settingsVisible">
    <div>
      <h4>Default Pipelines</h4>
      <ul>
        <li *ngFor="let pipeline of pipelines; let i = index">
          <lim-ui-checkbox
            [model]="pipeline['enabled']"
            [label]="pipeline['key']"
            (modelChange)="togglePipeline($event, i)"
          ></lim-ui-checkbox>
          <!-- <p>{{ pipeline['summary'] }}</p> -->
        </li>
      </ul>
    </div>
    <div>
      <h4>Default Vendors</h4>
      <ul>
        <li *ngFor="let dataSource of dataSources; let i = index" class="field-setting">
          <lim-ui-checkbox
            [model]="dataSource['enabled']"
            [label]="dataSource['name']"
            (modelChange)="toggleDataSource($event, i)"
          ></lim-ui-checkbox>
          <a (click)="toggleOnlyVendor(i)">Only</a>
        </li>
      </ul>
    </div>
  </div>

  <a (click)="bulkToolVisible = !bulkToolVisible" class="settings-link">Bulk Query Loader</a>
  <div class="settings" *ngIf="bulkToolVisible">
    <div>
      <label>Bulk Query List - One query per line</label>
      <textarea [(ngModel)]="queryListText" style="display: block; width: 300px; height: 300px; margin-bottom: 30px">
      </textarea>
      <button (click)="bulkCreateTests()">Add Tests</button>
      <button (click)="bulkCreateTests(true)">Add & Run Tests</button>
    </div>
  </div>

  <a (click)="columnSettingsVisible = !columnSettingsVisible" class="settings-link"
    >Column Visibility ({{ totalEnabledFields() }} of {{ totalFields() }})</a
  >
  <div class="settings" *ngIf="columnSettingsVisible">
    <div>
      <h4>Column Visibility</h4>
      <ul>
        <li *ngFor="let field of fields; let i = index" class="field-setting">
          <lim-ui-checkbox
            [model]="field['enabled']"
            [label]="field['label']"
            (modelChange)="toggleField($event, field['key'])"
          ></lim-ui-checkbox>
          <a (click)="toggleOnlyField(i)">Only</a>
        </li>
      </ul>
    </div>
  </div>

  <div>
    <button (click)="export()">Export All Results</button>
  </div>

  <nav class="tabs">
    <div
      class="tab"
      *ngFor="let test of tests; let i = index"
      (click)="showTest(i)"
      [class.active]="test['visible']"
      [class.ready]="(test.searchService.status$ | async) === SearchStatus.Ready"
      [class.loading]="(test.searchService.status$ | async) === SearchStatus.Loading"
      [class.done]="(test.searchService.status$ | async) === SearchStatus.Done"
    >
      {{ (test.searchService.query$ | async) || '- no query -' }}
    </div>
    <div class="tab-new-test">
      <button (click)="addTest()">Add Test</button>
    </div>
  </nav>

  <div class="result-sets">
    <article *ngFor="let test of tests; let i = index" class="result-set" [hidden]="!test['visible']">
      <test-results
        [searchService]="test['searchService']"
        [pipelines]="test['pipelines']"
        [dataSources]="test['dataSources']"
        (deleteTestEvent)="deleteTest(i)"
        (resultUpdateEvent)="updateResults(i, $event)"
      ></test-results>
    </article>
  </div>
</section>
