<section>
  <app-item-hidden-notification
    [item]="itemHiddenNotification"
    *ngIf="itemHiddenNotification"
    [notificationType]="'groupedItem'"
  />
  <div class="results-breadcrumb" *ngIf="!isMobile">
    <div (click)="navigateToResults()">
      <lim-ui-icon icon="angleLeftRegular" iconSize="small"></lim-ui-icon> Back to results
    </div>
  </div>
  <div class="details-container" id="details">
    <div *ngIf="isMobile" [ngClass]="responsiveHeroItemSticky ? 'sticky-padding' : 'non-sticky-padding'"></div>
    <div class="details-section" id="hero-item">
      <item-details [selectedItem]="currentSelectedItem" [selectedItemMatch]="match"></item-details>
      <part-search-matches
        *ngIf="hasMatchedItems"
        [selectedItemMatch]="match"
        (onItemSelect)="updateSelectedItem($event)"
      ></part-search-matches>
      <similar-items></similar-items>
    </div>
  </div>
</section>
