import { NgClass } from '@angular/common';
import { Component } from '@angular/core';
import { LottieComponent } from 'ngx-lottie';
import animation from '../../../assets/lottie/skeleton_loader_line.json';
import { AnimationComponent } from '../animation/animation.component';

@Component({
  selector: 'skeleton-loading-bar-animation',
  templateUrl: 'skeleton-loading-bar-animation.html',
  styleUrls: ['skeleton-loading-bar-animation.scss'],
  standalone: true,
  imports: [NgClass, LottieComponent]
})
export class SkeletonLoadingBarAnimation extends AnimationComponent {
  public override options = {
    animationData: animation
  };
}
