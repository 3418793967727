export const vendorLogos = [
  {
    vendor: 'Grainger',
    vendorImg: 'grainger-logo.svg'
  },
  {
    vendor: 'Amazon',
    vendorImg: 'amazon-logo.svg'
  },
  {
    vendor: 'Fastenal',
    vendorImg: 'fastenal-logo.svg'
  },
  {
    vendor: 'McMaster-Carr',
    vendorImg: 'mcmaster-logo.svg'
  },
  {
    vendor: 'Motion',
    vendorImg: 'motion-logo.svg'
  },
  {
    vendor: 'MSC',
    vendorImg: 'msc-logo.svg'
  },
  {
    vendor: 'Ebay',
    vendorImg: 'ebay-logo.svg'
  },
  {
    vendor: 'Walmart',
    vendorImg: 'walmart-logo.svg'
  },
  {
    vendor: 'Home Depot',
    vendorImg: 'new-home-depot-logo.svg'
  },
  {
    vendor: 'Zoro',
    vendorImg: 'zoro-logo.svg'
  },
  {
    vendor: 'Bing',
    vendorImg: 'bing-logo.svg'
  },
  {
    vendor: 'Google Shopping',
    vendorImg: 'google-shopping.png'
  },
  {
    vendor: 'Uline',
    vendorImg: 'uline-logo.svg'
  },
  {
    vendor: 'Ferguson',
    vendorImg: 'ferguson-logo.svg'
  },
  {
    vendor: 'Global Industrial',
    vendorImg: 'global-industrial-logo.png'
  },
  {
    vendor: 'Applied Industrial',
    vendorImg: 'applied-industrial-logo.png'
  },
  {
    vendor: 'RS Online',
    vendorImg: 'rs-logo.jpg'
  },
  {
    vendor: 'Radwell',
    vendorImg: 'radwell-logo.png'
  },
  {
    vendor: 'Advanced Auto',
    vendorImg: 'advanced-auto-logo.svg'
  },
  {
    vendor: 'Autozone',
    vendorImg: 'autozone-logo.png'
  },
  {
    vendor: 'Oreilly',
    vendorImg: 'oreilly-logo.svg'
  },
  {
    vendor: 'Napa',
    vendorImg: 'napa-logo.svg'
  }
] as const;

export const vendorLogosResponsive = [
  {
    vendor: 'Grainger',
    vendorImg: 'grainger-logo.svg'
  },
  {
    vendor: 'McMaster-Carr',
    vendorImg: 'mcmaster-logo.svg'
  },
  {
    vendor: 'Zoro',
    vendorImg: 'zoro-logo.svg'
  },
  {
    vendor: 'Fastenal',
    vendorImg: 'fastenal-logo.svg'
  },
  {
    vendor: 'Amazon',
    vendorImg: 'amazon-logo.svg'
  },
  {
    vendor: 'Ebay',
    vendorImg: 'ebay-logo.svg'
  },
  {
    vendor: 'Uline',
    vendorImg: 'uline-logo.svg'
  },
  {
    vendor: 'Advanced Auto',
    vendorImg: 'advanced-auto-logo.svg'
  },
  {
    vendor: 'Oreilly',
    vendorImg: 'oreilly-logo.svg'
  },
  {
    vendor: 'Motion',
    vendorImg: 'motion-logo.svg'
  },
  {
    vendor: 'MSC',
    vendorImg: 'msc-logo.svg'
  },
  {
    vendor: 'Walmart',
    vendorImg: 'walmart-logo.svg'
  },
  {
    vendor: 'Home Depot',
    vendorImg: 'new-home-depot-logo.svg'
  },
  {
    vendor: 'Google Shopping',
    vendorImg: 'google-shopping.png'
  },
  {
    vendor: 'Bing',
    vendorImg: 'bing-logo.svg'
  },
  {
    vendor: 'Autozone',
    vendorImg: 'autozone-logo.png'
  },
  {
    vendor: 'Napa',
    vendorImg: 'napa-logo.svg'
  },
  {
    vendor: 'Radwell',
    vendorImg: 'radwell-logo.png'
  },
  {
    vendor: 'Ferguson',
    vendorImg: 'ferguson-logo.svg'
  },
  {
    vendor: 'Global Industrial',
    vendorImg: 'global-industrial-logo.png'
  },
  {
    vendor: 'Applied Industrial',
    vendorImg: 'applied-industrial-logo.png'
  },
  {
    vendor: 'RS',
    vendorImg: 'rs-logo.jpg'
  }
] as const;

export const customerReviews = [
  {
    name: 'Francisco',
    jobTitle: 'Maintenance Manager',
    reviewContent: 'Saving money, saving time...I enjoy searching for parts now.',
    reviewerImage: 'francisco.png'
  },
  {
    name: 'Andrew',
    jobTitle: 'Maintenance Manager',
    reviewContent: 'I used to have like 8 tabs open when shopping, now I just have the one.',
    reviewerImage: 'andrew.png'
  }
] as const;

export const popularItems = [
  {
    itemTitle: 'Angular Ball Bearing',
    partNumber: '5202',
    partImage: 'angular-ball-bearing.svg',
    priceVariance: 90,
    noOfVendors: 6
  },
  {
    itemTitle: 'Siemens Combustion Actuator',
    partNumber: 'SQM48.497B9',
    partImage: 'siemens-combustion-actuator.svg',
    priceVariance: 93,
    noOfVendors: 5
  },
  {
    itemTitle: 'Sloan Flush Valve',
    partNumber: 'REGAL 186-0.5 XL',
    partImage: 'manual-flush-valve.svg',
    priceVariance: 35,
    noOfVendors: 4
  },
  {
    itemTitle: 'Baldor Motor',
    partNumber: 'VEM3558T',
    partImage: 'baldor-motor.svg',
    priceVariance: 80,
    noOfVendors: 6
  },
  {
    itemTitle: 'Motorcraft Oil Filter',
    partNumber: 'FL-500s',
    partImage: 'oil-filter.svg',
    priceVariance: 72,
    noOfVendors: 6
  },
  {
    itemTitle: 'Dayton V-Belt',
    partNumber: '5L350',
    partImage: '5l350-v-belt.svg',
    priceVariance: 82,
    noOfVendors: 8
  }
] as const;

export const affliateLinks = [
  {
    vendor: 'Amazon',
    vendorKey: 'amazon',
    affiliateData: {
      key: 'tag',
      value: 'na0aafc-20'
    },
    affiliateLinkType: 'parameter',
    useAffiliateLink: true
  }
] as const;
