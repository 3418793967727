<div class="location-container" *ngIf="displayZipCode && displayZipCode.length > 0">
  <svg xmlns="http://www.w3.org/2000/svg" width="2" height="18" viewBox="0 0 2 18" fill="none">
    <path d="M1 18L1 0" stroke="#E8E8E8" />
  </svg>
  <fa-icon [icon]="faLocationDot" size="lg"></fa-icon>
  <div class="location-text">
    <span>Deliver to</span>
    <span class="zipcode">{{ displayZipCode }}</span>
  </div>
</div>
