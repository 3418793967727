import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { CONFIG } from '../../../environments/environment';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  withCredentials: true
};

const searchUrl = CONFIG.API_URL + 'internal/search';

export enum SearchStatus {
  Ready,
  Loading,
  Done,
  Error
}

@Injectable({
  providedIn: 'root'
})
export class SearchTestService {
  private readonly _searchResults = new BehaviorSubject<any>([]);
  private readonly _meta = new BehaviorSubject<any>({});
  private readonly _query = new BehaviorSubject<string>('');
  private readonly _status = new BehaviorSubject<SearchStatus>(SearchStatus.Ready);
  private readonly _fields = new BehaviorSubject<any>([
    {
      key: 'id',
      label: 'ID',
      enabled: true,
      type: 'number'
    },
    {
      key: 'total_items',
      label: 'Matches',
      enabled: true,
      type: 'number'
    },
    {
      key: 'itemGroupValues.id',
      label: 'Item Group Values',
      enabled: true,
      type: 'number',
      formatter: (cell: any, formatterParams: any, onRendered: any) => {
        let data = cell.getData(); // Get the data for the cell
        let items = data.itemGroupValues; // Assuming "objectIds" is the field with your array of object IDs

        if (!items || items.length === 0) {
          return null;
        }

        // You can format the object IDs here, e.g., as a comma-separated string
        let formattedIds = items.map((obj) => obj.id).join(', ');

        return formattedIds; // Return the formatted content for the cell
      }
    },
    {
      key: 'vendor.name',
      label: 'Vendor',
      enabled: true,
      type: 'text'
    },
    {
      key: 'manufacturerPartNumber',
      label: 'Part No.',
      enabled: true,
      type: 'text'
    },
    {
      key: 'upc',
      label: 'UPC',
      enabled: true,
      type: 'text'
    },
    {
      key: 'sku',
      label: 'SKU',
      enabled: true,
      type: 'text'
    },
    {
      key: 'title',
      label: 'Title',
      enabled: true,
      type: 'text',
      maxWidth: 300
    },
    {
      key: 'brandName',
      label: 'Brand',
      enabled: true,
      type: 'text'
    },
    {
      key: 'description',
      label: 'Description',
      enabled: true,
      type: 'text'
    },
    {
      key: 'technicalDetails',
      label: 'Tech Specs',
      enabled: true,
      type: 'text'
    },
    {
      key: 'mainImageUrl',
      label: 'Image',
      enabled: true,
      type: 'image'
    },
    {
      key: 'productUrl',
      label: 'URL',
      enabled: true,
      type: 'url'
    },
    {
      key: 'price',
      label: 'Price',
      enabled: true,
      type: 'number'
    },
    {
      key: 'per',
      label: 'Per',
      enabled: true,
      type: 'number'
    },
    {
      key: 'pricePer',
      label: 'Price Per',
      enabled: true,
      type: 'number'
    },
    {
      key: 'requestId',
      label: 'Request ID',
      enabled: true,
      type: 'number'
    },
    {
      key: 'searchHistoryId',
      label: 'Search ID',
      enabled: true,
      type: 'number'
    }
  ]);

  readonly searchResults$ = this._searchResults.asObservable();
  readonly query$ = this._query.asObservable();
  readonly status$ = this._status.asObservable();
  readonly fields$ = this._fields.asObservable();
  readonly meta$ = this._meta.asObservable();

  constructor(private http: HttpClient) {}

  getPipelines(): any {
    return this.http.get<any>(CONFIG.API_URL + 'internal/pipelines', httpOptions);
  }

  getDataSources(): any {
    return this.http.get<any>(CONFIG.API_URL + 'internal/vendors', httpOptions);
  }

  getQuery(): string {
    return this._query.getValue();
  }

  setQuery(query: string): void {
    this._query.next(query);
  }

  toggleFieldVisibility(key: any, value: boolean): void {
    let fields = this._fields.getValue();

    let index = fields.findIndex((field: any) => {
      return field.key === key;
    });

    fields[index].enabled = value;

    this._fields.next(fields);
  }

  search(query: string, options?: any): void {
    this._status.next(SearchStatus.Loading);

    let params = {
      query: query,
      filters: {},
      group: null,
      sort: null,
      page: null,
      pipeline: []
    };

    params = Object.assign(params, options);

    this.http.post<any>(searchUrl, params, httpOptions).subscribe({
      next: (data) => {
        this._searchResults.next(data.items);
        this._meta.next(data.meta);
        this._status.next(SearchStatus.Done);
      },

      error: (err) => {
        console.error(err);
        this._status.next(SearchStatus.Error);
      }
    });
  }
}
