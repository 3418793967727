<div class="pre-search-best-match">
  <div>
    <div class="best-match-group-items" *ngFor="let item of groupedItems">
      <div class="item" (click)="showDetails(item)">
        <div class="item-image-container">
          <img
            [src]="
              item.mainImageUrl && item.mainImageUrl.length === 0 ? '/assets/images/placeholder-img.png' : item.mainImageUrl
            "
            class="item-image"
            alt="Item image"
            onerror="this.onerror=null; this.src='/assets/images/placeholder-img.png'"
          />
        </div>
        <div class="item-content">
          <div class="vendor-logo">
            <img [src]="item.vendor.logoUrl" alt="Vendor logo" />
          </div>
          <div class="title">{{ item.title }}</div>
        </div>
        <div class="price-container">
          <div class="item-price">
            {{ item.price | currency }}
          </div>
          <shipping-details [item]="item"></shipping-details>
        </div>
        <div>
          <button (click)="openItemSite(item['productUrl'], item.vendor,item.id); $event.stopPropagation()" class="visit-site-btn">
           Visit Site
          </button>
          <div class="label-container" *ngIf="showLabelControls">
            <button
              (click)="setLabel(item.id, 'vote', 1); $event.stopPropagation()"
              [class]="{ selected: item.labels['vote'] == 1 }"
            >
              <img src="assets/images/thumbs-up-icon.svg" />
            </button>
            <button
              (click)="setLabel(item.id, 'vote', -1); $event.stopPropagation()"
              [class]="{ selected: item.labels['vote'] == -1 }"
            >
              <img src="assets/images/thumbs-down-icon.svg" />
            </button>
          </div>
        </div>
      </div>
      <div class="line-break" *ngIf="groupedItems.length>1"></div>
    </div>
  </div>
  <div class="more-options"  *ngIf="match && match.count > 5">
    <div class="all-vendors">
      <div>
        <strong>{{ match.count - 5 }}</strong> other options from
      </div>
      <div class="other-vendors" *ngIf="match && match.vendors.length > 0" [limUiTooltip]="vendorTooltip" >
        <img
          *ngFor="let vendorKey of vendorLogoKeys"
          [src]="'assets/images/vendor-icons/icon-' + vendorKey + '.png'"
          class="vendor-icon"
        />
      </div>
    </div>
    <div class="see-all" (click)="showDetails(groupedItems[0])">
      See All
    </div>
  </div>
</div>
