//Adopted from lim-ui panel component to create search panel component for search MVP

import { Attribute, Component, Optional, Input, HostBinding } from '@angular/core';
import { NgClass, NgIf } from '@angular/common';
import { Aliases } from '@limblecmms/lim-ui';
import { MinimalIconButtonComponent } from '@limblecmms/lim-ui';
@Component({
  selector: 'custom-lim-ui-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.scss'],
  standalone: true,
  imports: [NgClass, NgIf, MinimalIconButtonComponent]
})
export class CustomPanelComponent {
  @HostBinding('class') public readonly classes = 'scroll-height-inheritance';
  // The title text to be rendered at the top of the panel.
  @Input() title: string;
  @Input() useCustomTitle: boolean | undefined;
  // Whether or not the watermark image in the bottom end corner is shown.
  @Input() showWatermark: boolean = false;
  // Whether or not the panel can be collapsed
  @Input() collapsable: boolean = false;
  @Input() collapsed: boolean;
  collapsedIcon: Aliases = 'angleUpRegular';
  // Set to true if you want the panel to extend the height of the page
  @Input() extendEntireHeightOfPage: boolean = false;
  // Set to true if there is content in the footer, like pagination, that needs to overlay the content
  @Input() paginationFooterOverlay = false;
  @Input() table = false;
  /**
   * If set to true, there will be no side padding on the panel.
   * This enables borders to extend to the end of the panel in tables.
   */
  @Input() noSidePadding: boolean = false;

  /** If set to true there will be a border along the bottom of the header section */
  @Input() showHeaderDivider: boolean = false;

  @Input() floatFooter: boolean = false;

  @Input() noBorder: boolean = false;
  @Input() noPointerEvents: boolean = false;
  @Input() topBorder: boolean = false; //use either noBorder or topBorder

  public constructor(@Optional() @Attribute('title') title: string) {
    this.collapsed = false;
    this.title = title;
  }

  public handleCollapserClick() {
    this.collapsed = !this.collapsed;
    this.collapsedIcon = this.collapsed ? 'angleDownRegular' : 'angleUpRegular';
  }
}
