import { NgFor, NgIf, CurrencyPipe } from '@angular/common';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

import { TooltipDirective } from '@limblecmms/lim-ui';

import { SearchService } from '../_services/search.service';
import { FeatureService } from '../_services/feature.service';

import { ShippingDetailsComponent } from '../shipping-details/shipping-details.component';

import { ItemGroupMatch } from '../_types/attributeGrouping';
import { Subscription } from 'rxjs';

@Component({
  selector: 'pre-search-best-match',
  standalone: true,
  imports: [NgIf, NgFor, ShippingDetailsComponent, CurrencyPipe, TooltipDirective],
  templateUrl: './pre-search-best-match.component.html',
  styleUrl: './pre-search-best-match.component.scss'
})
export class PreSearchBestMatchComponent implements OnInit {
  @Input()
  groupedItems!: any[];

  @Input()
  match: ItemGroupMatch | undefined;

  @Input()
  vendorLogoKeys!: any[];

  @Input()
  vendorTooltip!: string;

  @Output() openDetailsPage = new EventEmitter();
  @Output() labelSelection = new EventEmitter();

  public showLabelControls = false;
  public featureServiceSub?: Subscription;

  constructor(public searchService: SearchService, public featureService: FeatureService) {}

  ngOnInit() {
    this.featureServiceSub = this.featureService.features$.subscribe(() => {
      this.showLabelControls = this.featureService.enabled('super-admin');
    });
  }

  openItemSite(productUrl: string, vendor: any, itemId: number) {
    this.searchService.openItemSite(productUrl, vendor, itemId);
  }

  showDetails(item: any) {
    this.openDetailsPage.emit(item);
  }
  setLabel(itemId: number, label: string, value: number) {
    this.labelSelection.emit({ itemId, label, value });
  }
}
