import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgIf } from '@angular/common';
import { Subscription } from 'rxjs';

import { SearchService } from '../_services/search.service';
import { isMobile } from '../_util/mobile.util';

import { PartSearchFieldComponent } from '../part-search-field/part-search-field.component';

@Component({
  selector: 'error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss'],
  standalone: true,
  imports: [PartSearchFieldComponent, NgIf]
})
export class ErrorPageComponent implements OnInit, OnDestroy {
  public isMobile: boolean = false;
  public searchErrorSub?: Subscription;

  constructor(public searchService: SearchService) {}

  ngOnInit(): void {
    this.isMobile = isMobile();

    this.searchErrorSub = this.searchService.error$.subscribe((error) => {
      if (error?.status) {
        this.searchService.resetError();
      }
    });
  }

  ngOnDestroy(): void {}
}
