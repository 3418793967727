import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Subject, Observable, EMPTY, throwError, take } from 'rxjs';
import { CONFIG } from '../../environments/environment';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  withCredentials: true
};

@Injectable({
  providedIn: 'root'
})
export class FeatureService {
  private readonly features = new BehaviorSubject<object>({});

  readonly features$ = this.features.asObservable();

  constructor(private http: HttpClient) {}

  enabled(feature: string): boolean {
    const features = this.features.getValue();
    return typeof features[feature] !== 'undefined' && features[feature] === true;
  }

  setFeatures(features: any): void {
    this.features.next(features);
  }

  getFeatures(): Observable<any> {
    const request = this.http.get(CONFIG.API_URL + 'features', httpOptions);

    request.subscribe({
      next: (data: any) => {
        this.features.next(data);
      },
      error: (response) => {}
    });

    return request;
  }
}
