<section [hidden]="!items.length">
  <ng-container *ngIf="!isMobile; else responsiveHeader">
    <h2>Similar Items ({{ items.length }})</h2>
  </ng-container>
  <ng-template #responsiveHeader>
    <h2>Similar Items</h2>
  </ng-template>
  <ng-container *ngIf="!isMobile; else responsiveSimilarItems">
    <div class="container">
      <lim-ui-minimal-icon-button icon="chevronLeft" (click)="scrollLeft()" class="scroll-left"></lim-ui-minimal-icon-button>
      <div #scrollContainer class="scroll-container">
        <part-search-item-card *ngFor="let item of items" [item]="item" [view]="'similar-items'"></part-search-item-card>
      </div>
      <div class="blur"></div>
      <lim-ui-minimal-icon-button
        icon="chevronRight"
        (click)="scrollRight()"
        class="scroll-right"
      ></lim-ui-minimal-icon-button>
    </div>
  </ng-container>
  <ng-template #responsiveSimilarItems>
    <div class="container">
      <part-search-item-card *ngFor="let item of items" [item]="item" [view]="'similar-items'"></part-search-item-card>
    </div>
  </ng-template>
</section>
