import packageJson from '../../package.json';

export const CONFIG = {
  API_URL: 'https://api.dev.partosphere.limblestaging.com/v1/',
  geoKey: '147962e36260424385b5c1676a1f71c4',
  geoApifyKey: '147962e36260424385b5c1676a1f71c4',
  FQDN: 'https://dev.partosphere.limblestaging.com',
  recaptcha: {
    siteKey: '6LcPLQQoAAAAAEIxiOOSw5aVJ5F8ZoNfO5fztpjE'
  },
  version: packageJson.version
};
