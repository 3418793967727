import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Subject, Observable, EMPTY, throwError } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { datadogRum } from '@datadog/browser-rum';
import { CONFIG } from '../../environments/environment';
import { parseISO, isBefore, differenceInDays } from 'date-fns';
import { HeapService } from './heap.service';

const authUrl = CONFIG.API_URL + 'auth';
const userUrl = CONFIG.API_URL + 'users';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  withCredentials: true
};
const USER_KEY = 'auth-user';

interface User {
  id: number;
  firstName: string;
  lastName: string;
  companyName: string;
  email: string;
  phoneNumber: number;
  zipCode: number;
  vendorPrefs: Array<any>;
}

export enum UserAccessStatus {
  Full,
  Trial,
  ExpiredTrial
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private readonly _isLoggedIn = new BehaviorSubject<boolean>(false);
  private readonly _error = new BehaviorSubject<any>(null);
  private readonly _accountUpdated = new Subject<void>();
  private readonly _daysInTrialRemaining = new Subject<number>();

  readonly userAccessStatus = new BehaviorSubject<UserAccessStatus | null>(null);
  readonly isLoggedIn$ = this._isLoggedIn.asObservable();
  readonly userAccessStatus$ = this.userAccessStatus.asObservable();
  readonly daysInTrialRemaining$ = this._daysInTrialRemaining.asObservable();
  readonly currentUser$ = new BehaviorSubject<User | null>(null);

  readonly error$ = this._error.asObservable();
  readonly accountUpdated$ = this._accountUpdated.asObservable();

  constructor(private http: HttpClient, private readonly heapService: HeapService) {
    let isLoggedIn = this.getUser() !== null;

    this._isLoggedIn.next(isLoggedIn);
  }

  private saveUser(user: User): void {
    window.localStorage.removeItem(USER_KEY);
    window.localStorage.setItem(USER_KEY, JSON.stringify(user));
  }

  /**
   * Checks if user has full access or is on a free trial
   */
  private checkUserAccessStatus(user: any) {
    const { freeTrialExpiration } = user;
    // null value is an existing user or paid user
    if (!freeTrialExpiration) {
      this.userAccessStatus.next(UserAccessStatus.Full);
      return;
    }

    // only update state here if in trial. Full access state is handled above and
    // we don't want to update view state (based on localStorage) if access is expired trial
    if (this.userAccessStatus.value === UserAccessStatus.Trial || this.userAccessStatus.value === null) {
      const trialExpirationDate = parseISO(freeTrialExpiration);
      const trialIsValid = isBefore(new Date(), trialExpirationDate);

      this.userAccessStatus.next(trialIsValid ? UserAccessStatus.Trial : UserAccessStatus.ExpiredTrial);

      if (freeTrialExpiration && trialIsValid) {
        const diff = differenceInDays(parseISO(freeTrialExpiration), new Date());
        this._daysInTrialRemaining.next(diff);
      }
    }
  }

  public deleteUser(): void {
    window.localStorage.removeItem(USER_KEY);
  }

  public getUser(): User | null {
    const userKey = window.localStorage.getItem(USER_KEY);

    let user: any = null;

    if (userKey) {
      try {
        user = JSON.parse(userKey);
        this.checkUserAccessStatus(user);
        this.heapService.identifyUser(user);
      } catch (e) {
        console.error('Error parsing user', e);
      }
    }
    return user;
  }

  public subscribeCheckLogin(): Observable<User> {
    const userId = this.getUser()?.id;
    if (!userId) {
      this._isLoggedIn.next(false);
      return EMPTY; // Return an empty Observable when no user ID
    }

    return this.http.get<User>(`${userUrl}/${userId}`, httpOptions).pipe(
      tap((data: User) => {
        console.log('User is logged in');
        this.saveUser(data);
        this._isLoggedIn.next(true);
        this.currentUser$.next(data);
        this._error.next(null);
      }),
      catchError((response) => {
        console.log('Error checking login', response);
        this._isLoggedIn.next(false);
        this.currentUser$.next(null);
        this._error.next(response.error.message);
        return throwError(response);
      })
    );
  }

  public isLoggedIn(): boolean {
    return this._isLoggedIn.value;
  }

  public initMonitoring(user: any): void {
    const url = window.location.href;
    let env;

    if (url.includes('search.limble.com')) {
      env = 'prod';
    } else if (url.includes('partosphere.limblestaging.com')) {
      env = 'staging';
    } else {
      return;
    }

    datadogRum.init({
      applicationId: '039d5bd0-a961-440a-bb1f-58c5a5c007e5',
      clientToken: 'pub3356d988227e1831b3c70508fb22709b',
      site: 'datadoghq.com',
      service: 'limble-search',
      env: env ?? '',
      // TODO: version: this.limbleVersion ?? "",
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'allow',
      allowedTracingUrls: [
        (traceURL: string) => traceURL.startsWith('https://search.limble.com'),
        (traceURL: string) => traceURL.startsWith('https://partosphere.limblestaging.com'),
        (traceURL: string) => traceURL.startsWith('https://api.search.limble.com'),
        (traceURL: string) => traceURL.startsWith('https://api.partosphere.limblestaging.com')
      ],
      beforeSend: (event: any) => {
        // discard a RUM error if status code is 401, that just means that got timed out of the app.
        //see these docs: https://docs.datadoghq.com/real_user_monitoring/guide/enrich-and-control-rum-data/?tab=event#discard-a-frontend-error
        if (event?.resource?.status_code === 401 || event?.resource?.status_code === 403) {
          return false;
        }
        return true;
      }
    });

    datadogRum.setUser({
      id: user.id,
      customerID: user.customer.id,
      name: `${user.firstName} ${user.lastName}`,
      email: user.email
    });

    datadogRum.startSessionReplayRecording();
  }

  login(email: string, password: string): Observable<any> {
    const request = this.http.post(
      authUrl + '/login',
      {
        email,
        password
      },
      httpOptions
    );

    request.subscribe({
      next: (data: any) => {
        this.saveUser(data.user);
        this._isLoggedIn.next(true);
        this._error.next(null);
      },
      error: (response) => {
        this._isLoggedIn.next(false);
        this._error.next(response.error.message);
      }
    });

    return request;
  }

  updateAccount(
    firstName: string,
    lastName: string,
    companyName: string,
    email: string,
    currentPassword: string,
    password: string
  ): Observable<any> {
    const u = this.getUser();
    if (!u) {
      return new Observable();
    }
    const request = this.http.post(
      userUrl + '/' + u.id,
      {
        firstName,
        lastName,
        companyName,
        email,
        password,
        currentPassword
      },
      httpOptions
    );

    request.subscribe({
      next: (data: any) => {
        this.saveUser(data.user);
        this._error.next(null);
        this._accountUpdated.next();
      },
      error: (response) => {
        this._error.next(response.error.message);
      }
    });

    return request;
  }

  register(
    firstName: string,
    lastName: string,
    companyName: string,
    email: string,
    password: string,
    phoneNumber: number
  ): Observable<any> {
    return this.http.post(
      authUrl + '/register',
      {
        firstName,
        lastName,
        companyName,
        email,
        password,
        phoneNumber
      },
      httpOptions
    );
  }

  forgotPassword(email: string): Observable<any> {
    return this.http.post(
      authUrl + '/forgot-password',
      {
        email
      },
      httpOptions
    );
  }

  resetPassword(token: string, password: string): Observable<any> {
    return this.http.post(
      authUrl + '/reset-password',
      {
        token,
        password
      },
      httpOptions
    );
  }

  logout(): void {
    const request = this.http.post(authUrl + '/logout', {}, httpOptions);

    request.subscribe({
      next: (data: any) => {
        console.log('LimSearch: User logged out');
        this.deleteUser();
        this._isLoggedIn.next(false);
        window.location.href = '/';
      },
      error: (response) => {
        console.log('LimSearch: Error logging out');
        this.deleteUser();
        this._isLoggedIn.next(false);
        this._error.next(response.error.message);
        window.location.href = '/';
      }
    });
  }

  activateAccount(inviteCode: string, email: string, initialPass: string, confirmPass: string): Observable<any> {
    return this.http.post(
      authUrl + '/invite',
      {
        inviteCode,
        email,
        initialPass,
        confirmPass
      },
      httpOptions
    );
  }

  sendVerificationEmail(): Observable<any> {
    return this.http.post(authUrl + '/send-verification-email', {}, httpOptions);
  }

  verifyRecaptcha(token: string): Observable<any> {
    const request = this.http.post(
      authUrl + '/verify-captcha',
      {
        token
      },
      httpOptions
    );
    return request;
  }

  refreshUser(): void {
    const user = this.getUser();

    if (user) {
      this.http.get(`${userUrl}/${user.id}`, httpOptions).subscribe({
        next: (data: any) => {
          this.saveUser(data);
          this._error.next(null);
        },
        error: (response) => {
          this._error.next(response.error.message);
        }
      });
    }
  }

  saveVendorPrefs(vendorPrefs: object): void {
    this.http
      .post(
        userUrl + '/vendor-prefs',
        {
          vendors: vendorPrefs
        },
        httpOptions
      )
      .subscribe({
        next: (data: any) => {
          let user = this.getUser();
          if (user && data.vendorPrefs) {
            user.vendorPrefs = data.vendorPrefs;
            this.saveUser(user);
          }

          this._error.next(null);
        },
        error: (response) => {
          this._error.next(response.error.message);
        }
      });
  }

  createApiKey(): Observable<any> {
    return this.http.post(userUrl + '/api-key', {}, httpOptions);
  }
}
