<ul class="mobile-menu" id="sidebar" (click)="hideSideMenu()">
  <li class="close-menu-header">
    <fa-icon [icon]="faxmark" size="sm" (click)="hideSideMenu(); $event.stopPropagation()"></fa-icon>
  </li>
  <ng-container *ngIf="isLoggedIn">
    <li>
      <a routerLink="/extension">Extension</a>
    </li>
    <li>
      <a routerLink="/account">Edit Account</a>
    </li>
    <li>
      <a routerLink="/" [queryParams]="{ vendorPrefs: 1 }">Vendor Preferences</a>
    </li>
    <li class="logout">
      <div (click)="logout()">Log Out</div>
    </li>
  </ng-container>
  <ng-container *ngIf="!isLoggedIn">
    <li>
      <a routerLink="/login">Log In</a>
    </li>
    <li>
      <a routerLink="/register">{{ this.router.url.includes('/explore') ? 'Start Free Trial' : 'Sign Up' }}</a>
    </li>
    <li>
      <a routerLink="/extension">Extension</a>
    </li>
  </ng-container>
</ul>
