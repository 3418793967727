<div class="main-container">
  <div class="child-container">
    <div class="login-cover-image">
      <div class="image-text">Confidently find the cheapest part in a fraction of the time</div>
    </div>
  </div>
  <div class="child-container">
    <div class="form-container">
      <h1 class="reset-pass-banner">Password Reset</h1>
      <form name="resetPasswordForm" (ngSubmit)="f.form.valid && resetPassword()" #f="ngForm" novalidate>
        <div class="form-group hoverboundary">
          <span>
            <label for="password">New Password</label>
            <input
              type="password"
              class="form-control"
              name="password"
              [(ngModel)]="resetPasswordForm.password"
              required
              minlength="6"
              #password="ngModel"
              id="newPassword"
            />
          </span>
          <span class="unhideOnHover">
            <lim-ui-icon
              [icon]="showPassword ? eyeIcon : eyeSlashIcon"
              class="unhideOnHoverIcon"
              iconSize="small"
              (click)="togglePasswordVisibility('newPassword')"
            ></lim-ui-icon>
          </span>
        </div>
        <div class="form-group hoverboundary">
          <span>
            <label for="password">Confirm New Password</label>
            <input
              type="password"
              class="form-control"
              name="confirmPassword"
              [(ngModel)]="resetPasswordForm.confirmPassword"
              required
              minlength="6"
              #password="ngModel"
              id="confirmPassword"
            />
          </span>
          <span class="unhideOnHover">
            <lim-ui-icon
              [icon]="showPassword ? eyeIcon : eyeSlashIcon"
              class="unhideOnHoverIcon"
              iconSize="small"
              (click)="togglePasswordVisibility('confirmPassword')"
            ></lim-ui-icon>
          </span>
        </div>
        <div class="error-container" *ngIf="resetPasswordError">
          {{ errorMsg }}
        </div>
        <button type="submit" class="main-page-btn">Reset Password</button>
      </form>
    </div>
  </div>
</div>
