import { Component, Input, OnChanges, SimpleChanges, OnInit, Output, EventEmitter, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { NgFor, NgIf } from '@angular/common';

import { isMobile } from '../_util/mobile.util';

import { SearchService } from '../_services/search.service';

import { CheckboxComponent, IconComponent, PrimaryButtonComponent } from '@limblecmms/lim-ui';
import { Subscription } from 'rxjs';
import { AuthService } from '../_services/auth.service';

@Component({
  selector: 'vendor-prefs',
  templateUrl: './vendor-prefs.component.html',
  styleUrls: ['./vendor-prefs.component.scss'],
  standalone: true,
  imports: [IconComponent, NgIf, NgFor, CheckboxComponent, PrimaryButtonComponent]
})
export class VendorPrefsComponent implements OnInit, OnChanges {
  public visible: boolean = false;

  @Input() show: boolean = false;

  @Output() onClose = new EventEmitter();

  @HostListener('document:click', ['$event'])
  clickout(event: any) {
    if (!this.visible) {
      return;
    }
    if (event.target.closest('.vendor-prefs-link')) {
      return;
    }
    if (this.show && !event.target.closest('#vendor-prefs')) {
      this.hideModal();
    }
  }

  public isMobile: boolean = false;
  public user: any;
  public vendorPrefs: Array<any> = [];
  public vendorPrefMap: any = {};
  public userSub?: Subscription;
  public commonVendors: Array<string> = ['ebay', 'googleShopping', 'bing'];

  public commonVendorPrefs: Array<any> = [];
  public otherVendorPrefs: Array<any> = [];

  constructor(public searchService: SearchService, public router: Router, public authService: AuthService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.show) {
      this.show = changes.show.currentValue;

      // Delay visibility change so the clickout event above doesn't
      // just immediately close the window again
      setTimeout(() => {
        this.visible = this.show;
      });
    }
  }

  ngOnInit(): void {
    this.isMobile = isMobile();
    this.user = this.authService.getUser();

    if (this.user?.vendorPrefs) {
      this.vendorPrefs = this.user.vendorPrefs.sort((a: any, b: any) => {
        return a.name.localeCompare(b.name);
      });

      this.commonVendorPrefs = this.vendorPrefs.filter((vendorPref: any) => {
        return this.commonVendors.includes(vendorPref.key);
      });

      this.otherVendorPrefs = this.vendorPrefs.filter((vendorPref: any) => {
        return !this.commonVendors.includes(vendorPref.key);
      });

      this.vendorPrefs.forEach((vendorPref: any) => {
        this.vendorPrefMap[vendorPref.id] = vendorPref.enabled;
      });
    }
  }

  updateVendorPrefs(value: any, vendorId: any) {
    this.vendorPrefMap[vendorId] = value;

    let data = this.vendorPrefs.map((vendorPref: any) => {
      return {
        id: vendorPref.id,
        enabled: this.vendorPrefMap[vendorPref.id]
      };
    });

    this.authService.saveVendorPrefs(data);
  }

  hideModal() {
    this.show = false;
    this.onClose.emit(false);
  }
}
