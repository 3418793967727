<div
  class="panel-wrapper scroll-height-inheritance"
  [ngClass]="{
    'panel-watermark': showWatermark,
    collapsable: collapsable,
    collapsed: collapsed,
    'no-side-padding': noSidePadding,
    'extend-entire-height-of-page': extendEntireHeightOfPage,
    'lim-table': table,
    'no-border': noBorder,
    'top-border': topBorder,
    'no-pointer-events': noPointerEvents
  }"
>
  <div
    *ngIf="title || useCustomTitle"
    class="panel-header"
    [ngClass]="{
      'graph-header': noSidePadding,
      'panel-header-divider': showHeaderDivider
    }"
    (click)="handleCollapserClick()"
  >
    <div *ngIf="title" class="panel-title" (click)="handleCollapserClick()">
      {{ title }}
    </div>
    <div *ngIf="useCustomTitle" class="panel-title">
      <ng-content select="[panelCustomTitle]"></ng-content>
    </div>
    <div class="panel-header-end">
      <ng-content select="[buttons]"></ng-content>
      <ng-container *ngIf="collapsable">
        <lim-ui-minimal-icon-button
          [superMinimal]="true"
          [icon]="collapsedIcon"
          iconSize="medium"
          (click)="handleCollapserClick(); $event.stopPropagation()"
          class="icon-hover"
        ></lim-ui-minimal-icon-button>
      </ng-container>
    </div>
  </div>
  <div class="panel-header-content">
    <ng-content select="[panelHeaderContent]"></ng-content>
  </div>
  <div
    limUiScrollWhileDragging
    class="panel-content scroll-height-inheritance"
    [ngClass]="{
      'pagination-footer-overlay': paginationFooterOverlay,
      'pagination-footer-small': floatFooter
    }"
  >
    <ng-content></ng-content>
  </div>
  <div class="panel-footer" [ngClass]="{ 'float-footer': floatFooter }">
    <ng-content select="[panelFooter]"></ng-content>
  </div>
</div>
