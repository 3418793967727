<section id="vendor-prefs">
  <div class="vendor-prefs-overlay" [class]="{ hidden: !visible }"></div>
  <div [class]="{ hidden: !show }" class="container">
    <header>
      <h3>Vendor Preferences</h3>
      <lim-ui-icon icon="xmark" (click)="hideModal()"></lim-ui-icon>
    </header>
    <div class="content">
      <h4 class="note">Choose your preferred vendors (check all that apply)</h4>
      <h4 class="list-heading">Commonly Filtered Vendors</h4>
      <ul class="vendors">
        <li *ngFor="let vendor of commonVendorPrefs" class="vendor">
          <lim-ui-checkbox
            [model]="vendorPrefMap[vendor.id]"
            (modelChange)="updateVendorPrefs($event, vendor.id)"
            [label]="vendor.name"
          />
        </li>
      </ul>

      <h4 class="list-heading">Other Vendors</h4>
      <ul class="vendors">
        <li *ngFor="let vendor of otherVendorPrefs" class="vendor">
          <lim-ui-checkbox
            [model]="vendorPrefMap[vendor.id]"
            (modelChange)="updateVendorPrefs($event, vendor.id)"
            [label]="vendor.name"
          />
        </li>
      </ul>
    </div>
    <footer>
      <button class="done" (click)="hideModal()">Done</button>
    </footer>
  </div>
</section>
