<div style="position: relative">
  <div class="part-search-container">
    <input
      name="part_search"
      id="part-search-field"
      [(ngModel)]="query"
      class="part-search"
      (keydown.enter)="search()"
      (focus)="showQueryQualityDropdown()"
      (blur)="showQueryQuality = false"
      placeholder="Search by Part Name, Part Detail, etc."
    />
    <button class="search-button" (click)="search()">
      <lim-ui-icon icon="magnifyingGlass" iconSize="small" />
    </button>
  </div>
  <query-quality-dropdown
    [query]="query"
    [show]="showQueryQuality"
    style="width: 100%; position: absolute; top: 56px"
  ></query-quality-dropdown>
</div>
