import { Component, OnDestroy, OnInit } from '@angular/core';
import { AsyncPipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription, distinctUntilChanged } from 'rxjs';

import { SearchService } from '../_services/search.service';
import { HeapService } from '../_services/heap.service';
import { isMobile } from '../_util/mobile.util';

import { PrimaryButtonComponent, IconComponent, ModalService } from '@limblecmms/lim-ui';
import { QueryQualityDropdownComponent } from '../query-quality-dropdown/query-quality-dropdown';
import { PartSearchModalComponent } from '../part-search-modal/part-search-modal.component';

@Component({
  selector: 'part-search-field',
  templateUrl: './part-search-field.component.html',
  styleUrls: ['./part-search-field.component.scss'],
  standalone: true,
  imports: [FormsModule, PrimaryButtonComponent, AsyncPipe, IconComponent, QueryQualityDropdownComponent]
})
export class PartSearchFieldComponent implements OnInit, OnDestroy {
  public showQueryQuality: boolean = false;
  public query: string = '';
  public isMobile: boolean = false;

  private querySub?: Subscription;

  constructor(
    public searchService: SearchService,
    public router: Router,
    public heapService: HeapService,
    public modalService: ModalService
  ) {}

  ngOnInit(): void {
    this.isMobile = isMobile();

    // if searching from landing page while logged out, get the cached search query
    if (localStorage.getItem('searchQuery')) {
      const query = localStorage.getItem('searchQuery') || '';
      this.query = query;
      this.searchService.setSearchQuery(this.query || '');
      this.router.navigate(['/searching'], { skipLocationChange: true, replaceUrl: true });
      localStorage.removeItem('searchQuery');
    }

    this.querySub = this.searchService.query$.pipe(distinctUntilChanged()).subscribe((query: any) => {
      // This prevents angular from throwing a change detection error, bit of a hack, but it works for now
      // https://www.youtube.com/watch?v=O47uUnJjbJc
      // setTimeout(() => {
      //   console.log("this was fired",query);
      //   this.query = query;
      // }, 0);

      this.query = query;
    });
  }

  async search(): Promise<any> {
    this.showQueryQuality = false;
    await this.searchService.setSearchQuery(this.query || '');
    this.heapService.trackEvent('search', { query: this.query });
    this.router.navigate(['/searching'], { skipLocationChange: true, replaceUrl: true });
  }

  async openPartSearchModal(): Promise<any> {
    const activePartSearchModal = this.modalService.getActiveModal();
    if (activePartSearchModal !== null) {
      return activePartSearchModal;
    }
    const modalRef = this.modalService.open(PartSearchModalComponent);
    const modalResult = await modalRef.result;
    return modalResult;
  }

  async showQueryQualityDropdown() {
    if (this.isMobile) {
      this.showQueryQuality = false;
      const modalRef = await this.openPartSearchModal();
      if (modalRef === false) {
        return;
      }
      await this.searchService.setSearchQuery(modalRef);
      await this.search();
    } else {
      this.showQueryQuality = true;
    }
  }

  ngOnDestroy(): void {
    this.querySub?.unsubscribe();
  }
}
